@import "../vars";


.category-list {
  margin: 2em 0 1em;


  & > .category-list__item {
    float: left;
    margin: 0 1em 1em 0;
    overflow: hidden;
    position: relative;
    width: calc(25% - 0.75em);

    &:nth-child(4n + 4) {
      margin: 0 0 1em;
    }


    & > a {
      display: inline-block;
      max-width: 162px;
      text-decoration: none;

      & > .pic {
        border: 1px solid #c8c8c8;
        display: block;
        position: relative;
        text-align: center;

        & > img {
          max-width: 162px;
          display: block;
          margin: 0 auto;
        }

        & > .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }


      &:hover {

        .category-list__item-title {
          color: $linkColor--hover;
          transition: color 0.2s ease;
        }

        .bg {
          background: rgba(0,0,0,0.3);
          transition: background 0.2s ease;
        }
      }
    }


    .category-list__item-title {
      color: $linkColor;
      display: block;
      font-size: 16px;
      height: 48px;
      line-height: 1.25;
      padding: 0.25em 0;
      text-decoration: none;
      text-align: center;
    }

  }

  &.brands {
    & > .category-list__item {
      text-align: center;
    }
  }
}



.flexbox {
  .category-list__item {

    .category-list__item-title {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
    }
  }
}
