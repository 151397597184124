@import "../vars";

.catalog-list {
  margin: 2em 0;
}


// Стили карточки в каталоге
.catalog-item {
  cursor: default;
  padding: 0 0 5.5em;
  margin: 1em 0 0;
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0.2);

  .compare {
    text-align: center;
  }

  .wrappa,
  .product_photo {
    float: none;
    width: 100%;
  }


  .prod_podrobnee {
    text-align: center;
  }

  .product_photo {
    border: 1px solid $inputBorderColor;
    text-align: center;

    .show_product {
      display: block;
      overflow: hidden;

      & > img {
        display: block;
        height: auto;
        max-width: 400px;
        width: 100%;
      }
    }
  }


  .catalog-item__title {
    border-bottom: 1px solid $inputBorderColor;
    padding: 4px 10px;

    & > .show_product {
      color: $linkColor;
      display: block;
      font-size: 16px;
      line-height: 1.25;
      text-decoration: none;

      &:hover {
        color: $linkColor--hover;
      }
    }
  }


  .product-prod_prices {
    float: left;
    position: relative;
    width: 50%;
  }


  .prod_price_old {
    float: right;
    width: 50%;
    font-size: 14px;
    line-height: 2;
    color: #626262;
    text-align: right;
    text-decoration: line-through;
  }


  .prod_price {
    color: $black;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
  }


  .prod-bottom-wrapper {
    position: absolute;
    bottom: 0;
    height: 5em;
    left: 0;
    right: 0;
  }

  .show_product {

    &.button {
      margin: 0.5em 0 0;
    }
  }
}


// Раскладка карточек в каталоге
.catalog-list {

  & > .catalog-item {
    float: left;
    margin: 0 2em 2em 0;
    width: calc(25% - 1.5em);

    &:nth-child(3n + 4) {
      margin: 0 0 2em;
    }
  }
}


.no-prod {
  font-size: 14px;
  clear: both;
  padding: 1em 0 0;
}



