@import "vars";


.nav-block {
	border-top: 1px solid #dcdcdc;
	padding: 1em 0;
}


nav {

	float: left;
	padding: 0 0 0 1em;
	width: 79%;

	.menu_level_1 {
		display: table;
		list-style: none;
		height: 31px;

		&.active {
			overflow: visible;
		}


		& > li {
			display: table-cell;
			position: relative;
			text-align: center;

			& > a {
				color: $textColor;
				font-size: 16px;
				display: block;
				padding: 4px 0.5em;
				position: relative;
				text-decoration: none;
				text-align: center;
				user-select: none;
				white-space: nowrap;

				&:before {
					background: $linkColor;
					content: '';
					position: absolute;
					top: -16px;
					left: 0.5em;
					right: 0.5em;
					visibility: hidden;
					height: 2px;
					opacity: 0;
					z-index: 2;
				}
			}


			&:not(.selected) {
				&:hover {
					& > a {
						color: $linkColor;

						&:before {
							background: $linkColor;
							opacity: 1;
							height: 4px;
							transition: opacity 0.2s ease;
							visibility: visible;
						}
					}
				}
			}

			&.subcat {

				&.selected {
					& > a {
						cursor: pointer;
					}
				}

				& > a {
					padding: 3px 2em 7px 1em;
				}


				&:before {
					color: $textColor;
					content: '\f107';
					font: normal 22px/1 "FontAwesome";
					position: absolute;
					right: 0.5em;
					margin-top: -14px;
					top: 50%;
				}

				&:hover {

					.menu_level_2 {
						opacity: 1;
						transition: all 0.3s ease;
						visibility: visible;
					}

					&:before {
						color: $linkColor;
						margin-top: -10px;
					}

				}
			}

			
			&.selected {

				& > a {
					color: $linkColor;
					cursor: default;


					&:before {
						background: $linkColor;
						opacity: 1;
						transition: opacity 0.2s ease;
						visibility: visible;
					}
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
		}

	}


	// Всплывающее меню
	.menu_level_2 {
		background: rgba(246, 246, 246, 0.9);
		box-shadow: 1px 2px 7px -2px #ccc;
		display: table;
		list-style: none;
		margin: 0;
		padding: 1em 1.5em;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 99999;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		width: 100%;


		& > li {
			display: table-row;
			padding: 0;

			&:not(.selected) {
				& > a {

					&:hover {
						color: $linkColor;
						text-decoration: underline;
					}
				}
			}

			&.selected {
				& > a {
					color: $linkColor;
					text-decoration: none;

					&.empty {
						cursor: default;
					}
				}
			}

			& > a {
				color: $textColor;
				display: block;
				font-size: 14px;
				padding: 7px 0;
				line-height: 1.2;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.menu_level_3 {
		display: block;
		list-style-type: disc;
		margin: 0 2em 0 1em;


		& > li {
			& > a {
				display: block;
				color: #eee;
			}
			
			&:not(.selected) {

				& > a {
					&:hover {
						color: #e55727;
						text-decoration: underline;
					}
				}
			}

			&.selected {
				& > a {
					color: #e55727;
					text-decoration: none;
				}
			}
		}
	}

}

