@import "vars";


.reg_reg-section {

  table {
    max-width: 443px;
    width: 100%
  }

  .input-text {
    width: 100%;
  }

  .reg_col_1 {
    font-size: 14px;
    padding: 0 1em 0 0;
    width: 150px;
  }

  .reg_col_2 {
    display: block;
    margin: 0.5em 0;
    position: relative;
  }

  .button {
    margin: 1em 0 0;
  }

}

