@import "vars";


.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;
}



main {
  overflow: hidden;
  width: 100%;
  
  & > .container {
    padding: 1em 0 0;
  }
}


aside {
  float: left;
  padding: 0 1em 3em;
  width: 270px;

}


.content-block {
  float: right;
  min-height: calc(100vh - 641px);
  padding: 0 1em;
  margin: 0 0 6em;
  width: calc(100% - 270px);
}

