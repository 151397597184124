@import "vars";
@import "icon";


input::-ms-clear {
  display: none;
}


html {

}


body {
  background: $bgColor;
  font: normal 16px/1.5 "Lato", Arial, sans-serif;
  color: $textColor;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  -webkit-font-smoothing: antialiased;
  transition: background 0.05s ease;
}


.lock body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


table {
  border-collapse: collapse;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  color: #49281f;
  font-size: 28px;
}

h2 {
  color: #49281f;
  font-size: 24px;
}

h3 {
  color: #49281f;
  font-size: 21px;
}


h1 , h2, h3, h4, h5, h6, p {
  padding: 0 0 0.5em;
}

a {
  color: $linkColor;
  transition: color 0.2s ease;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0,0,0,0.2);
}

a:hover {
  color: $linkColor--hover;
  transition: color 0.2s ease;
  text-decoration: underline;
}


::-webkit-input-placeholder {
  color: $textColor + 60%;
  font-size: 13px;
  line-height: 18px;
}

::-moz-placeholder {
  color: $textColor + 60%;
  font-size: 13px;
  line-height: 18px;
}

:-moz-placeholder {
  color: $textColor + 60%;
  font-size: 13px;
  line-height: 18px;
}

:-ms-input-placeholder {
  color: $textColor + 60%;
  font-size: 13px;
  line-height: 18px;
}


.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  width: 100%;

}


.alert {
  color: red;
}



.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center;
}

.noscript_alert > span {
  font-weight: bold;
  color: red;
}

.content-inner {
  font-size: 1em;
}

.content-inner h1 {
  color: $linkColor;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}


.content-inner .title {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: bold;
}


.path {
  color: $linkColor;
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 1em;
  padding: 1em 0 2em;
  position: relative;

  &:before {
    background: linear-gradient(to right, #c9c3ba 0%, #c9c3ba 15%, rgba(255, 255, 255, 0) 70%);
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
  }
}


.inner {
  .path {
    background: none;
  }
}



.content {
  margin: 1em 0 0;
  position: relative;

  img {
    height: auto;
    max-width: 800px;
    width: 100%;
  }

}


main {
  position: relative;
}



button,
.button {
  @include button();
}


.desktop {
  button,
  .button {
    transition: background 0.3s ease, border 0.3s ease, color 0.2s ease;
  }
}


.input-text,
.input-textarea {
  @include input();
}


.yashare_text {
  text-align: right;
}

html.ie9 * {
  border-radius: 0 !important;
}


.call-order-wrapper {
  & > .button {
    padding: 11px 24px 12px 39px;
    position: relative;

    &:before {
      background: url('/assets/images/sprite.png') 3px 2px no-repeat #efda9e;
      content: '';
      border-radius: 50%;
      display: block;
      border: 1px solid #382507;
      position: absolute;
      top: 0;
      left: 0;
      height: 28px;
      width: 28px;
    }
  }
}


.overlay {
  position: fixed;
  background: rgba(0,0,0,0.5);;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 900;
}


.locked {
  .overlay {
    opacity: 1;
    transition: opacity 0.2s ease;
    visibility: visible;
  }
}


.mob-icon {
  font: normal 1.5em/1.2 "FontAwesome";
  position: absolute;
  visibility: hidden;
}



.mobile .mob-icon,
.tablet .mob-icon {
  visibility: visible;
}


.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}


.pollitra > div {
  display: inline-block;
  vertical-align:top;
  margin: 5px 0 3px 5px;
}

.pollitra  input {
  display:none;
}

.pollitra label {
  display:block;
  border:3px solid rgba(0, 0, 0, 0);
}

.pollitra label span {
  height: 24px;
  width: 24px;
  display: block;
  cursor: pointer;
  margin: 1px;
}

.pollitra  input:checked + label {
  border-color: #AAAAFF;
}

.citronLoader {
  position:absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, .8);
  z-index: 2;
}

.citronLoader span {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
  width:32px;
  height:32px;
  background:url(../images/ajaxloader.gif) 0 0 no-repeat;
}

.citronLoaderBig {
  position:absolute;
  top: 0;
  left: 0;
  background:white;
  background:rgba(255, 255, 255, .8);
  z-index:2;
}

.citronLoaderBig span {
  position:absolute;
  top:100px;
  left:48%;
  width:66px;
  height:66px;
  background:url(../images/citronloader.gif) 0 0 no-repeat;
}

#ajaxedsearch {
  position:absolute;
  z-index:-1;
  width:1px;
  height:1px;
  overflow:hidden;
}


.content-block.filtrovannoe {
  padding: 10px;
}


.icon {
  cursor: default;
  display: block;
  position: absolute;
  height: 24px;
  width: 24px;
  left: -1.333em;
  top: 0.5em;
  text-align: center;

  &:before {
    content: '';
    font: normal 1em/1 "FontAwesome";
    color: #000;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.5em;
    z-index: 2;
  }

  & > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    z-index: 4;
  }
}


.prd_pages_bottom,
.prd_pages_top {
  color: #999;
  font-size: 13px;
  text-align: right;
  
  & > a {
    display: inline-block;
    padding: 0 4px;
    
    &.sel_page {
      color: $textColor;
      text-decoration: none;
    }
  }
}



.tablet,
.mobile,
.desktop {

  body {
    opacity: 1;
    transition: opacity 0.4s ease;
    visibility: visible;
  }
}


.gallery-photo {
  margin: 1em 0 0;
  padding: 0 2em;

  .owl-nav {

    &.disabled {
      display: block;
    }

    .owl-next {
      right: -0.75em;
    }

    .owl-prev {
      left: -0.75em;
    }

    .owl-prev,
    .owl-next {
      color: #30a12a;
      font-size: 3em;
      font-weight: bold;
      opacity: 0.5;

      &:hover {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }
  }

  .photo-item {

    & > a {
      & > img {
        display: block;
        height: auto;
        max-width: 240px;
        width: 100%;
      }
    }
  }


}


.photo_block,
.album-block {
  float: left;
  margin: 0 1em 1em 0;
  width: calc(25% - 0.75em);

  &:nth-child(4n + 4) {
    margin: 0 0 1em;
  }

  .photo_photo,
  .album-photo {

    & > a {
      & > img {
        display: block;
        height: auto;
        max-width: 360px;
        width: 100%;
      }
    }
  }

  .album-title {
    padding: 0 0.5em;
    margin: 0.5em 0 0;
    text-align: center;

    & > a {
      font-size: 14px;
      margin: 0.5em 0;
    }
  }

}


.photos_text {
  font-size: 14px;
}

.search_box {

  .search-text {
    font-size: 14px;
    line-height: 1.333;
  }

  img {
    margin: 0 1em 4em 0 !important;
    max-width: 170px;
  }
}





.example {
  background: $linkColor;
  color: #fff;
  font-weight: 700;
  display: inline-block;
}


.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 73%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}


.redz {
  color: #df141e;
}


.radio {
  width: 15px;
  height: 15px;

  & + label {
    cursor: pointer;
  }

  &:not(checked) {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      padding: 0 0 0 35px;

      &:before {
        border-radius: 50%;
        background: #FFF;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $linkColor;
        border: 1px solid #fff;
        opacity: 0;
        transition: all .2s;
      }


    }
  }

  &:checked {
    & + label {
      &:after {
        opacity: 1;
      }
    }
  }
}


.dostavka_radio {

}



