@import "../vars";


.news-block {
	padding: 0 0 1em;


	.news {
		overflow: hidden;
		padding: 1em 1em 0 2em;
		position: relative;
		margin: 0 0 1em;
	}

	.news-block__title {

		& > a {
			display: block;
			font-size: 16px;
			font-weight: bold;
			line-height: 1.333;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}
	}


	.news-block__text {
		font-size: 15px;
		margin: 0.5em 0 1.5em;
		line-height: 1.333;
	}


	.news-block__date {
		position: relative;

		& > span {
			background: #eeeeee;
			color: #a8a8a8;
			font-size: 14px;
			padding: 1px 2px 0;
			white-space: nowrap;
			position: relative;
			z-index: 1;
		}

		&:before {
			background: #dadad9;
			content: '';
			height: 1px;
			position: absolute;
			top: 50%;
			left: -2em;
			right: -1em;
		}
	}

	.news-all {
		margin: 7px 0 0;
		text-align: center;

		& > a {
			font-size: 14px;
		}
	}
}


.news_datetitle {
	display: table;
	margin: 0 0 1em;
	width: 100%;
}


.news_date,
.news_title,
.news_icon {
	display: table-cell;
}


.news_date {
	display: table-cell;
	font-size: 12px;
	text-align: right;
	width: 116px;
	min-width: 114px;
	
	& > span {
		font-size: 12px;
		color: #a8a8a8;
		padding: 1px 3px 2px;

		&.dt {
			background: $linkColor;
			color: #fff;
			display: inline-block;
		}


		&:first-child {
			font-size: 12px;
			padding: 1px 0;
			display: block;
		}
	}
}


.news_title {
	font-size: 16px;
	font-weight: bold;
	vertical-align: middle;
}

.news_icon {
	padding: 0 1em 0 0;
	vertical-align: bottom;
	width: calc(60px + 1em);
}

.news_navigation {
	display: table;
	width: 100%;

	& > .prev_news {
		text-align: left;
	}

	& > .next_news {
		text-align: right;
	}

	& > .all_news {
		text-align: center;
	}

	& > .all_news,
	& > .next_news,
	& > .prev_news {
		display: table-cell;
		font-size: 13px;
		width: 33.333%;

		& > a {
			text-decoration: underline;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}


.article {

	.news_icon {
		& > img {
			display: block;
			height: auto;
			max-width: 60px;
			width: 100%;
		}
	}


	.news_text {
		padding: 0 0 2em;
	}

	.news_image {
		float: left;
		max-width: 130px;
		margin: 0 1em 0.5em 0;
		width: 100%;
	}


	@include media(sm) {
		.news_text {
			font-size: 13px;
		}
	}
}


.content-inner .article {
	border-bottom: 1px solid $borderColor;
	padding: 1em 0;

	&:last-of-type {
		border-bottom: none;
	}
}

