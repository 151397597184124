@import "vars";


// Обратная связь
.feedback-form {
	margin: 1em 0 0;

	.feedback-col-6 {
		float: left;
		padding: 0 0.5em 0 0;
		width: 67%;

		&.last {
			padding: 0 0 0 0.5em;
			width: 33%;

			.feedback-block {
				margin: 0 0 0.5em;
			}
		}
	}

	.feedback-button-block {
		float: left;
		margin: 4px 0 0;
		width: 100%;
	}

	input {
		width: 100%;
	}

	textarea {
		resize: none;
		height: 154px;
		width: 100%;
	}

	.button {
		width: auto;
	}
	
	.form_title {
		position: static;
	}

	.feedback-block {

		&.check-agree {
			margin: 0;
		}
	}
}



.form_content {
	position: relative;
}


.has-error {

	& > .input-text,
	& > .input-textarea {
		border-color: $colorError !important;
	}
}


.has-success {
	& > .input-text,
	& > .input-textarea {
		border-color: $colorOk !important;
	}
}


.form_title {
	color: #543e1c;
	font-size: 13px;
	font-weight: bold;
	position: absolute;
	pointer-events: none;
	top: 14px;
	left: 0.5em;
	z-index: 10;
}


.help-block {
	font-size: 12px;
	position: absolute;
	top: 8px;
	right: 1em;

	&.form-error {
		color: $colorError;
	}
}


.feedback-block.module-inner {
	margin: 2em 0 0;
	padding: 0 0 1em;
}
