@import "../vars";

// Раскладка
.product {

  .product_left_block {
    float: left;
    overflow: hidden;
    padding: 0 2em 0 0;
    position: relative;
    width: 37%;
  }

  .product_mid_block {
    float: right;
    margin: 1.2em 0 0;
    padding: 0 0 0 2em;
    width: 63%;
  }
}


.compare {

  input[type=checkbox] {
    cursor: pointer;
  }

  .compare_link,
  label {
    cursor: pointer;
    font-size: 12px;
    vertical-align: text-bottom;
  }
}



.product-labels {
  position: absolute;
  pointer-events: none;
  top: 2em;
  left: 0;

  & > span {
    display: block;
    color: #fff;
    cursor: default;
    margin: 0 0 6px;
    padding: 0;
    position: relative;
    font: normal 12px/24px "Arial", "Helvetica", sans-serif;
    text-align: center;

    &:after {
      border-top: 12px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      right: -10px;
      bottom: 0;
    }


    &.label-new {
      background: $linkColor;
      width: 65px;

      &:after {
        border-top-color: $linkColor;
        border-bottom-color: $linkColor;
      }
    }

    &.label-hit {
      background: #29e4e5;
      width: 38px;

      &:after {
        border-top-color: #29e4e5;
        border-bottom-color: #29e4e5;
      }
    }

    &.label-spec {
      background: #e52929;
      width: 57px;

      &:after {
        border-top-color: #e52929;
        border-bottom-color: #e52929;
      }
    }
  }
}


.sort_panel {
  background: #eeeeee;
  color: $textColor;
  font-weight: 100;
  font-size: 15px;
  padding: 10px 1em 8px;
  margin: 3em 0 1em;
  
  & > span {
    margin: 0 0.5em;
    position: relative;

    &:after {
      content: '';
      color: $linkColor;
      font: normal 14px/1 "FontAwesome";
    }

    & > a {
      margin: 0 3px 0 0;
    }

    &.this_sort {
      font-weight: bold;
    }

    &.desc_sort {
      &:after {
        content: "\f161";
      }
    }

    &.asc_sort {

      &:after {
        content: "\f160";
      }
    }
  }
}




// Оформление
.product {
  
  .short_description {
    margin: 1em 0 0;
    max-width: 400px;

    * {
      color: $textColor;
      font-size: 13px;
      line-height: 1.333;
    }
  }

  .rate_results {

    &._get {
      pointer-events: all;
      z-index: 20;
    }

    &:before {
      color: #222;
      top: -4px;
      pointer-events: none;
      right: -37px;
    }

  }

  .rating-stars {
    position: relative;
    z-index: 3;
  }


  .dop_atr {
    display: inline-block;
    padding: 0 0 1em;
    min-width: 240px;
  }


  .price-block {
    max-width: 400px;
  }

  .characters-table__row {
    color: #222;
    font-size: 14px;

    & > b {
      font-weight: normal;
    }
  }


  .characters-table {
    max-width: 400px;

    .characters-table__row {
      color: #222;
      line-height: 1.75;
      position: relative;

      &:before {
        border-bottom: 1px dashed $borderColor;
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 0;
      }

      .rate_results {
        min-width: auto;
        position: static;
        vertical-align: middle;

        &:before {
          padding: 4px 0;
          min-width: 174px;
          left: auto;
          top: -6px;
          text-align: right;
        }

        & > b {
          font-weight: normal;
        }
      }

      a {
        color: $linkColor;

        &:hover {
          color: $linkColor--hover;
        }
      }

      & > b {
        float: left;
        padding: 0 1em 0 0;
      }

      & > span {
        float: right;
        padding: 0 0 0 1em;
      }


      & > span,
      & > b {
        background: #fff;
        position: relative;
        z-index: 10;
      }

      & > b {
        font-weight: normal;
      }
    }
  }

  .prod_dop_option {
    &.rating_block {
      color: #767676;
      font: 400 15px/1 Arial,Helvetica,sans-serif;
      max-width: 70%;


      .rate_results {
        color: #222;
        font-size: 13px;
        float: right;
        padding: 5px 0 5px 10px;
      }

      & > b {
        color: #222;
        font-weight: normal;
        line-height: 1.75;
        position: relative;
        z-index: 10;
      }
    }
  }


  .product_top {
    margin: 0 0 2em;
  }

  .prod_title {
    color: $linkColor;
    font-size: 18px;
    line-height: 1.25;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: none;
  }


  .product_photo {

    img {
      display: block;
      height: auto;
      max-width: 100%;
      width: 100%;
    }

    & > a {
      display: block;
      border: 1px solid #d5d4e5;
      overflow: hidden;
    }
  }


  .kolvo_buy_button_add {
    margin: 0 0 1em;
  }

  .prod-bnts {
    margin: 0.5em 0 0;
    max-width: 400px;

    .button {
      display: block;
      font-size: 16px;
      text-align: center;
      text-transform: none;
    }

    .fast_order {
      margin: 1em 0 0;
    }
  }


  .product-prod_prices {
    font-size: 32px;
  }

  .prod_price_old {
    color: #626262;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    text-decoration: line-through;
    position: relative;
  }

  .prod_big_price {
    display: inline-block;
    color: $black;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0.5em 0 0;
  }


  &.--hidden {
    display: none;
  }

}


.product_dop_photo_slider {


  .owl-carousel {
    padding: 0 1px 0 0;
  }

  .dop-photo__thumb {
    border: 1px solid #d5d4e5;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0.2);

    & > img {
      cursor: pointer;
      display: block;
    }
  }

  & > .owl-carousel {
    margin: 1em 0 0;

    .owl-stage-outer {
      padding: 0 0 1px;
    }


    & > .owl-nav {
      perspective-origin: center;
      
      .owl-prev,
      .owl-next {
        height: 46px;
        padding: 0;
        position: absolute;
        width: 15px;
        top: 0;
        text-indent: -9999px;

        &:hover {
          &:before {
            background-position: -15px -339px;
          }
        }

        &:before {
          background: url('/assets/images/sprite.png') 0 -339px no-repeat;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
        top: -23px;
        transform: rotate(180deg);
      }
    }
  }
}


// ВКЛАДКИ
.product_dop_modes {
  position: relative;
  font-size: 14px;
  margin: 0 0 2em;

  .product_dop_modes_content {
    background: #fff;
    position: relative;
    z-index: 10;
    padding: 1em 0;
    text-align: justify;
    margin: 0 0 1em;


    li,
    p {
      color: #382507;
      font-size: 14px;
    }


    a {
      font-size: 14px;
    }


    .product_dop_title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px;
    }
  }
}



.product_dop_modes_navigation {
  background: #f4f4f4;
  position: relative;
  height: 35px;
  padding: 0;
  z-index: 12;

  li {
    border-top: 2px solid transparent;
    position: relative;
    display: block;
    float: left;
    font-size: 14px;
    color: #475055;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    cursor: pointer;

    &:hover {

    }

    &.mod_selected {
      border-top: 2px solid $linkColor;
      cursor: default;
      color: $linkColor;
      background: #fff;
    }
  }

}


.chars_table {
  table-layout: fixed;
  max-width: calc(320px - 2em);
  width: 100%;

  & > tbody {
    & > tr {
      border-bottom: 1px solid $borderColor;
    }
  }

  .chars_title {
    width: 200px;
  }
}

.pr-recommended {
  border: none;
  padding: 10px 0;
}

.pr-recommended .product_dop_title {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 10px 25px;
}


.product-comments-form {
  float: left;
  padding: 0 1em 0 0;
  width: 300px;


  .form_title {
    position: static;
  }


  .form_block {
    margin: 0 0 1em;
  }


  .input-textarea,
  .input-text {
    display: block;
    width: 100%;
  }

  .input-textarea {
    resize: none;
    height: 7em;
  }
}



.fast_order_form--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  display: none;
}



.prod_selector {
  float: left;
  margin: 0 0 1.5em;
  width: 60%;

  .prod_selector_wrapper {
    display: inline-block;
    position: relative;

    &:after {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: calc(1em + 3px);
      transform: rotate(-40deg);
    }

    &:before {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: 1em;
      transform: rotate(40deg);
    }
  }


  .select_size {
    background: #fff;
    border: 1px solid #a8a8a8;
    border-radius: 0;
    color: #a8a8a8;
    cursor: pointer;
    min-width: 228px;
    outline: none;
    padding: 3px 1em;
    height: 33px;

    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important;

  }


  .prod_selector_title {
    display: inline-block;
    color: #696969;
    font: normal 15px/1 "Arial", "Helvetica", sans-serif;
    margin: 0 5% 0 0;
  }

}

select::-ms-expand {
  display: none;
}


.prod_selector_title {
  color: #382507;
  font-style: normal;
  font-size: 17px;
}

.prod-color-wrapper {
  display: inline-block;
  margin: 0.5em 0 0.5em;

  .prod-color-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    text-transform: lowercase;
  }
}


.prod_selector_size {

  .size_product {
    background: url('/assets/images/skin.png') no-repeat;
    border-radius: 7px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    height: 51px;
    margin: 0 5px;
    width: 51px;
  }
}


.responses_content {

  .message_box {
    float: right;
    width: calc(100% - 300px);
    padding: 0 0 1em 1em;
    margin: 0 0 2em;
  }


  .message_top {
    border-bottom: 1px solid $borderColor;
    display: table;
    width: 100%;
  }

  .message_text {
    margin: 0.5em 0 0;
  }


  .message_date,
  .message_name {
    display: table-cell;
  }


  .message_name {
    vertical-align: bottom;

    & > span {
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
    }
  }


  .message_date {
    float: right;
    text-align: center;

    & > span {
      font-size: 12px;
      color: #a8a8a8;
      padding: 1px 3px 2px;

      &.dt {
        background: $linkColor;
        color: #fff;
        display: block;
      }
    }
  }
}
