@import "../vars";


.nav-left {
	position: relative;

	.menu_level_1 {
		display: block;


		& > li {
			border-bottom: 1px solid #cccccc;
			list-style: none;
			transition: all 0.3s ease;
			-webkit-tap-highlight-color: $webkitTapColor;


			&:last-child {
				border-bottom: none;
			}

			& > a {
				display: block;
				color: #475055;
				font-size: 16px;
				line-height: 1.1;
				padding: 21px 1em 19px 2em;
				position: relative;
				text-decoration: none;
				transition: color 0.3s ease;

				&:after {
					content: '\f105';
					color: $linkColor;
					font: normal 16px/1 "FontAwesome";
					position: absolute;
					margin-top: -9px;
					top: 50%;
					left: 1em;
					transform: translate3d(0, 0, 0);
				}
			}

			&:not(.selected):hover {
				background: $linkColor;
				border-bottom-color: $linkColor;

				& > a {
					color: $white;
					text-decoration: none;

					&:after {
						color: $white;
						transform: translate3d(5px, 0, 0);
						transition: transform 0.2s ease-out, color 0.2s ease-in;
					}
				}
			}

			&.selected {
				background: $linkColor;
				cursor: default;
				user-select: none;

				& > a {
					color: $white;
					text-decoration: none;

					&:after {
						color: #fff;
						transform: rotate(90deg);
					}
				}

				&.subcats_exists {

					&:after {
						color: #fff;
						transform: rotate(90deg);
					}
				}
			}
		}
	}

	.menu_level_2 {
		background: #eeeeee;
		display: block;
		padding: 8px 0;

		& > li {
			list-style: none;
			padding: 0 0 0 2.5em;

			& > a {
				color: #382507;
				display: block;
				font-size: 16px;
				padding: 3px 5px 2px;
				position: relative;
				text-decoration: none;
			}

			&:not(.selected) {
				&:hover {
					
					& > a {
						color: $linkColor;
						text-decoration: underline;
					}
				}
			}
			
			&.selected {
				& > a {
					color: $linkColor;
					text-decoration: none;

					&:after {
						background: $linkColor;
					}
				}
			}
		}
	}

	.menu_level_3 {
		background: #f1f1f1;
		border-top: 1px solid #d7d7d7;
		display: none;
		padding: 0.5em 1em 0.75em 2em;

		& > li {
			list-style: disc;
			padding: 0.2em 0;

			& > span {
				& > a {
					color: #666;
					display: block;
					font-size: 0.86em;
					text-decoration: none;
				}
			}

			&.selected {
				color: #1c8db1;
			}

			&:hover {
				& > span {
					& > a {
						color: #1c8db1;
					}
				}
			}
		}
	}
}

