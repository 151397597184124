@import "vars";

// Раскладка футера
footer {

	.logo {
		float: left;
		padding: 0 4em 0 1em;
		margin: 0;
		width: 32%;
	}

	.search {
		float: left;
		padding: 0 1em;
		width: 45%;
	}


	.contacts-block {
		float: right;
		padding: 0 1em 0 3em;
		width: 23%;
	}
}



// Оформление
footer {
	background: $footerTopBgColor;
	padding: 80px 0 30px;
	position: relative;
	

	.company-description {
		color: $white;
		font-size: 16px;
		line-height: 1.2;
		padding: 0 0 1em;
	}

	.logo {
		& > a {
			display: block;
		}

		img {
			display: block;
			height: auto;
			margin: 0 0 1em;
			max-width: 232px;
			position: relative;
			z-index: 2;
			width: 100%;
		}
	}


	.ttl {
		color: $white - 50%;
		font-size: 12px;
		display: block;
		line-height: 1.333;
	}


	.pay-block {

	}
	

	.phones {
		display: block;
		margin: 0 0 1em;
		padding: 0 0 0 0.75em;
		position: relative;


		.phones-block {
			display: inline-block;

			.icon {

				left: -1em;
				top: 2px;

				&:before {
					content: '\f095';
					color: $white;
				}
			}
		}


		.phone-item {

			& > a {
				color: $white;
				cursor: default;
				font-size: 14px;
				font-weight: bold;
				text-decoration: none;

				.pref {
					font-weight: normal;
				}
			}
		}
	}


	.mail {
		margin: 0 0 1em;
		padding: 0 0 0 0.75em;
		position: relative;

		.icon {
			top: -1px;
			left: -1em;

			&:before {
				content: '\f0e0';
				font-size: 14px;
				color: $white;
			}
		}


		& > a {
			color: $white;
			display: block;
			font-size: 14px;
			text-decoration: underline;

			&:hover {
				color: $linkColor;
				text-decoration: underline;
			}
		}
	}


	.addr {
		padding: 0 0 0 0.75em;
		position: relative;
		margin: 0 0 1.75em;

		.icon {
			left: -0.75em;
			top: 5px;

			&:before {
				content: '\f041';
				font-size: 18px;
				color: $white;
			}
		}
		
		& > a {
			color: $white;
			display: block;
			font-size: 14px;
			
			&:hover {
				color: $linkColor;
			}
		}
	}

	.search {
		margin: 0.25em 0 0;

		& > .search-form {
			margin: 0 0 1.5em;
			width: 100%;
		}

		.company-description {
			padding: 0 0 0.75em;
		}
	}


	.social-block {
		margin: 0 0 1em;

		.social-block__item {
			display: inline-block;
			margin: 0 8px 0 0;

			a {
				border-radius: 50%;
				display: block;
				text-align: center;
				height: 30px;
				position: relative;
				transition: opacity 0.2s ease;
				text-decoration: none;
				width: 30px;

				&:before {
					content: '';
					display: inline-block;
					font: normal 18px/31px "FontAwesome";
					color: $white;
					text-decoration: none;
				}

				&.si1 {
					background: #2f76a6;

					&:before {
						content: "\f189";
					}
				}

				&.si2 {
					background: #f4992c;

					&:before {
						content: "\f263";
					}
				}

				&.si3 {
					background: #3c5b9b;

					&:before {
						content: "\f09a";
					}
				}

				&.si4 {
					background: #2daae1;

					&:before {
						content: "\f099";
					}

				}

				&.si5 {
					background: #ca3b29;

					&:before {
						content: "\f0d5";
					}
				}

				&.si6 {
					background: #014587;

					&:before {
						content: "\f1fa";
					}
				}

				&.si7 {
					background: #fe3432;

					&:before {
						content: "\f16a";
					}
				}

				&.si8 {
					background: #c13584;

					&:before {
						content: "\f16d";
					}
				}

				&:hover {
					opacity: 0.6;
				}
			}
		}
	}

	.counters-block {
		margin: 1em 0 0;
		text-align: right;

		& > a {
			display: inline-block;
			margin: 0 5px;

		}

		& > .citron-logo__link {
			position: relative;
			vertical-align: text-bottom;

			&:hover {
				.citron-logo--hover {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.citron-logo--hover {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity 0.3s ease;
			visibility: hidden;
		}

	}
}



