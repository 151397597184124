@import "vars";

// Базовые стили owl carousel
.owl-carousel {
	display: none;
	overflow: hidden;
	height: 100%;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	visibility: hidden;
	z-index: 1;

	.owl-stage {
		position: relative;
		height: 100%;
		-ms-touch-action: pan-Y;
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		height: 100%;
		-webkit-transform: translate3d(0, 0, 0);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		height: 100%;
		width: 100%;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: $webkitTapColor;
		-webkit-touch-callout: none;
		will-change: transform;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		cursor: hand;
		user-select: none;
	}

	.owl-nav {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		height: 0;

		&.disabled {
			display: none;
		}

		.owl-prev,
		.owl-next {
			@include fontawesome() {
				color: #fff;
				cursor: pointer;
				font-size: 4em;
				line-height: 1;
				opacity: 0.73;
				padding: 0 12px;
				position: absolute;
				transform: translateY(-50%);
				transition: all 0.3s ease;
				user-select: none;

				&:hover {
					opacity: 1;
				}
			}
		}

		.owl-prev {

			left: 0.3em;
			right: auto;
		}

		.owl-next {
			left: auto;
			right: 0.3em;
		}

	}

	.owl-dots {
		background: rgba(0,0,0,0.7);
		border-radius: 0.3em;
		padding: 0.21426em 1em 0;
		position: absolute;
		left: 50%;
		bottom: 1em;
		transform: translateX(-50%);
		z-index: 100;

		&.disabled {
			display: none;
		}


		& > .owl-dot {
			display: inline-block;
			zoom: 1;

			&.active {

				& > span {
					background: rgb(25,48,80);
					background: rgba(25,48,80,0.2);
					border: 0.35643em solid #fff;
					padding: 0.28568em;
					margin: 0.35711em 0.28568em;
					width: 1.18em;
					height: 1.18em;
				}
			}

			& > span {
				border: 0.2851em solid rgba(0,0,0,0);
				margin: 0.71427em 0.64283em;
				background: #fff;
				display: block;
				transition: opacity 200ms ease;
				border-radius: 2.142854em;
			}
		}
	}

	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}

	.owl-animated-in {
		z-index: 0;
	}

	.owl-animated-out {
		z-index: 1;
	}

	.fadeOut {
		animation-name: fadeOut;
	}

	.owl-height {
		transition: height 500ms ease-in-out;
	}

	.owl-item .owl-lazy {
		opacity: 0;
		transition: opacity 400ms ease;
	}

	.owl-item img.owl-lazy {
		transform-style: preserve-3d;
	}


	&.owl-loaded {
		display: block;
		visibility: visible;
	}
	&.owl-loading {
		opacity: 0;
		display: block;
	}
	&.owl-hidden {
		opacity: 0;
	}
	&.owl-refresh .owl-item {
		display: none;
	}
	&.owl-drag .owl-item {
		user-select: none;
	}
	&.owl-grab {
		cursor: move;
		cursor: grab;
	}
	&.owl-rtl {
		direction: rtl;
	}
	&.owl-rtl .owl-item {
		float: right;
	}

}


// Стили слайдера на десктопах
.desktop {
	.slider-block {

		.slider {
			position: relative;


		}
	}
}


.slider-block {
	position: relative;

}



// Стили слайдера на главной
.slider {

	overflow: hidden;

	&.owl-carousel {

		.slider-item {
			position: relative;

			& > a {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

		}

		.owl-nav {
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}


		.item-label {
			position: absolute;
			bottom: 20%;
			left: 10%;
			will-change: transform;

			& > .item-label__link {
				display: block;
				text-decoration: none;
				
				& > h2 {
					background: rgba(32, 95, 22, 0.63);
					color: #fff;
					font-size: 36px;
					font-weight: normal;
					padding: 6px 11px 4px;
					line-height: 1;
					margin: 0 0 0.5em;
					text-transform: uppercase;
				}

				& > p {
					background: rgba(235,217,22, 0.63);
					color: #000;
					display: inline-block;
					font-size: 18px;
					line-height: 1;
					padding: 3px 5px 1px;
				}
			}

		}
	}

}




.no-js {
	.owl-carousel {
		display: block;
	}
}



@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


