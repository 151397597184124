$containerWidth: 1200px;

$white: #fff;
$firstColor: #009fc8;
$black: #222;
$blue: #2c2a7d;
$blue2: #2c2a7d;
$linkColor: #347ea7;                           // Цвет ссылок
$linkColor--hover: #79b9dc;                    // Цвет ссылок при наведении

$textColor: #515151;                           // Цвет текста
$webkitTapColor: rgba(0,0,0, 0.15);


$bgColor: #fff;                                // Цвет фона
$headerTopBgColor: #fff;                       // Цвет фона верхней шапки
$headerTopBgColorMobile: rgba(0,0,0,0.7);      // Цвет фона верхней шапки на мобильных


$footerTopBgColor: #1b4557;
$footerBottomBgColor: #1b4557;
$footerLinkColor: $blue;


// Цвета ошибок
$colorError: rgba(253, 30, 15, 0.85);

// Цвета ОК
$colorOk: rgba(169, 253, 0, 0.85);



// Borders
//$borderColor: #f36f21;
$borderColor: #bababa;
$borderRadius: 0;                      // Закругления


// Вертикальный градиент вверх
@mixin gradient($startColor, $endColor, $noGradiend) {

  @if($noGradiend == true) {
    background: $startColor;
  }
  @else {
    background: $startColor;
    background: linear-gradient(to top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
  }
}


// Кнопки
// Обычное состояние
$isButtonGradient: false;                   // Градиент да/нет? Если нет, используется цвет 1
$isButtonShadow: false;                    // Тень, да/нет
$isButtonBorder: false;                    // Рамка, да/нет

$buttonBgColor1: #347ea7;                  // Цвет фона 1 (нижний)
$buttonBgColor2: #347ea7;                  // Цвет фона 2 (верхний)
$buttonBorder: 2px solid #fff;             // Рамка кнопок
$buttonShadow: 1px 2px 3px 0 #a8a8a8;      // Тень у кнопок
$buttonColor: #fff;                        // Цвет текста кнопок
$buttonBorderRadius: 0;                    // Закругления

// наведение
$buttonBgColor1--hover: #00527f;
$buttonBgColor2--hover: #00527f;
$buttonColor--hover: #fff;
$buttonBorderColor--hover: #f8db9d;
$buttonShadow--hover: none;

// актив
$buttonBgColor1--active: #345460;
$buttonBgColor2--active: #345460;
$buttonColor--active: #fff;
$buttonBorderColor--active: #8d3400;
$buttonShadow--active: none;

// заблокированное
$buttonBgColor1--disabled: #868687;
$buttonBgColor2--disabled: #868687;
$buttonColor--disabled: #eee;
$buttonBorderColor--disabled: #8d3400;
$buttonShadow--disabled: none;


@mixin button() {

  background: $buttonBgColor1;
  border: none;
  border-radius: 0;
  display: inline-block;
  color: $buttonColor;
  cursor: pointer;
  font: normal 13px/100% "Arial", Helvetica, sans-serif;
  outline: none;
  padding: 11px 1em 10px;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0.2);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;


  &:hover {
    background: $buttonBgColor1--hover;
    color: $buttonColor--hover;
    transition: background 0.2s ease;
    text-decoration: none;
  }


  &:active {
    background: $buttonBgColor1--active;
    color: $buttonColor--active;
    text-decoration: none;
  }


  &.disabled {
    background: $buttonBgColor1--disabled;
    color: $buttonColor--disabled;
    cursor: default;
  }

  @content;

}




// Инпуты
$isInputBorder: false;                    // Есть ли рамка
$isInputShadow: false;                    // Есть ли тень

$inputColor: $textColor;                 // Цвет текста
$inputBg: #efefef;                        // Цвет фона
$inputBorderColor: $borderColor;          // Цвет рамки
$inputShadow: inset 0 2px 7px 0 #a6a6a7;  // Тень

@mixin input() {

  background: $inputBg;

  @if($isInputBorder == true) {
    border: 1px solid $inputBorderColor;
  }
  @else {
    border: 1px solid transparent;
  }

  @if($isInputShadow) {
    box-shadow: $inputShadow;
  }
  @else {
    box-shadow: none;
  }

  border-radius: 0;
  color: $inputColor;
  font-size: 13px;
  font-style: normal;
  line-height: 17px;
  padding: 7px 1em;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @content;
}



// Модули
$moduleBg: #fff;                              // Цвет фона модулей
$moduleMobileHeaderBg: #c1c1c1;               // Цвет фона заголовков модулей в мобайл
$moduleHeaderBg: #113c5d;                     // Цвет фона модулей
$moduleHeaderColor: #fff;                     // Цвет текста в шапке
$moduleMobileHeaderColor: #2c2c2c;            // Цвет текста в шапке
$moduleBorder: 0;                             // Рамка
$module_top__box-shadow: 0px 2px 3px #808080;


@mixin module($isMobile) {

  @if($isMobile == false) {
    background: $moduleBg;
  }
  
  margin: 1em 0 2em;

  @if($moduleBorder != 0) {
    border: $moduleBorder;
  }

  .module-header {

    @if($isMobile) {
      background: $moduleMobileHeaderBg;
    }
    @else {
      background: $moduleHeaderBg;
    }

    padding: 1em;

    & > span {

      @if($isMobile) {
        color: $moduleMobileHeaderColor;
      }
      @else {
        color: $moduleHeaderColor;
      }

      color: #475055;
      cursor: default;
      font-size: 18px;
      text-transform: uppercase;
    }

    text-align: center;
  }

  @content;
}





/*********************************************/
/* Горизонтальное меню */
$isTopMenuGradient: false;               /* Градиент да/нет? Если нет, используется цвет 1 */
$isTopMenuShadow: false;                /* Тень, да/нет          */
$isTopMenuBorder: false;                /* Рамка, да/нет         */
$isTopMenuBorderRounded: true;         /* Закругления, да/нет   */

$topMenuBgColor1: #1f0d09;              /* Цвет фона 1 (нижний)  */
$topMenuBgColor2: #1f0d09;              /* Цвет фона 2 (верхний) */
$topMenuBorder: 1px solid #aaa;         /* Рамка                 */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;  /* Тень у кнопок         */
$topMenuColor: #fff;                    /* Цвет текста           */
$topMenuBorderRadius: 1em;                /* Закругления           */
$topMenuColor--hover: #efad68;          /* Цвет текста на ховер  */

$topMenuBgColor1--selected: #fde3c8;    /* Цвет фон 1 на текущий пункт */
$topMenuBgColor2--selected: #fde3c8;    /* Цвет фон 2 на текущий пункт */

@mixin navtop() {

  @content;
}


@mixin fontawesome() {
  color: $white;
  font-family: "FontAwesome";
  font-size: 1.5em;

  @content;
}


/* Товар */
$prodTitleColor: #005f90;
$prodTextColor: $textColor;
$prodSecondColor: #8f8f8f;
$prodCurrentPriceColor: $black;
$prodOldPriceColor: #a4a4a4;
