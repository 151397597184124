@import "vars";


.popup-block__fon {
  background: rgba(0,0,0,.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 989;
  position: fixed;
}


.popup-block {
  background: #fff;
  box-shadow: 1px 3px 14px 0 rgba(0,0,0,0.2);
  display: none;
  font-size: 15px;
  width: 400px;
  position: fixed;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 990;


  &.reg-window {
    width: 448px;

    .addition-block {
      display: inline-block;
      margin: 0 0 0 1em;

      & > a {
        &:first-child {
          margin: 0 1em 0 0;
        }
      }
    }

    .restore_pass-section {
      padding: 1em;

      .popup-block__title {
        margin: 0;
      }

      .form_block {
        padding: 0.5em 0 1.75em;

        &.form_block__last {

        }

        &.last {
          padding: 0.5em 0 0;

          .form_content {
            .help-block {
              right: 25%;
            }
          }
        }
      }
    }

    .form_title {
      &.captcha_title {
        font-size: 12px;
        top: -9px;
      }
    }

    .form_block {
      margin: 0;
      padding: 0.5em 0 0.75em;

      &.form_block__last {
        display: inline-block;
      }

      &.key-title {
        padding: 0;
        position: relative;
        top: -15px;
      }

      &.last {

        .key-img {
          display: inline-block;
          height: auto;
          position: absolute;
          right: 0;
          top: -10px;
          width: 20%;
        }

        .input-text {
          display: inline-block;
          width: 78%;
        }
      }
    }
  }



  &.auth-ok {

    .popup-block__inner {
      text-align: center;
    }
  }

  &.auth-error {

    .popup-block__inner {
      text-align: center;
    }
  }


  &.add2cart {
    width: 416px;

    .popup-block__inner {
      text-align: center;
    }

    .popup-block__title {
      margin: 0 0 0.5em;
    }

    .popup-block__content {
      text-align: center;

      & > a {
        display: inline-block;

        &:first-child {
          margin: 0 1em 0 0;
        }
      }
    }
  }


  &.order-call {
  }


  &.add-prod-comment {

    .popup-block__inner {
      text-align: center;
    }
  }


  &.sendformok {
    display: block;
    padding: 0;
    text-align: center;

    .popup-block__inner {
      text-align: center;
    }

  }


  &.reg-done {
    display: block;
    padding: 0;
    text-align: center;

    .popup-block__inner {
      text-align: center;
    }
  }


  .popup-block__content {
    backface-visibility: hidden;
    text-align: center;
  }

  .form_block {
    padding: 0.5em 0 1em;
    position: relative;
    text-align: left;


    &.form_block__last {
      padding: 7px 0 0;

      & > .form_title {
        font-size: 11px;
        top: -16px !important;
      }
    }
  }

  .input-text {
    width: 100%;
  }

  .input-textarea {
    height: 8em;
    resize: none;
    width: 100%;
  }

  .popup-block__inner {
    padding: 1em;
    position: relative;
  }

  .popup-block__cross {
    cursor: pointer;
    position: absolute;
    top: -0;
    right: 0;
    height: 32px;
    width: 32px;
    z-index: 3;
    -webkit-tap-highlight-color: $webkitTapColor;
    z-index: 1000;

    &:before {
      content: '\f00d';
      color: $textColor;
      position: absolute;
      font: normal 22px/1 "FontAwesome";
      top: 4px;
      left: 9px;
      z-index: 2;
    }

    &:hover {

      &:before {
        color: $linkColor;
      }
    }

    &:active {
      &:before {
        top: 5px;
        left: 10px;
      }
    }
  }


  .popup-block__title {
    color: $textColor;
    font-size: 21px;
    font-weight: bold;
    margin: 0 0 0.25em;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    backface-visibility: hidden;
  }


  &.fast_order_form {
    padding: 1em;
    height: 14.5em;
    margin-top: -7.25em;


    .form_block {
      &.form_block__last {
        text-align: center;
      }
    }
  }
}



.popup-block {
  &.compare-popup {
    padding: 1em;
    top: 50%;
    left: 50%;
    height: 800px;
    margin: -400px 0 0 calc(-600px + 1em);
    transform: none;
    width: calc(1200px - 2em);

    @include media(xlg) {
      margin: -400px 0 0;
      left: 1em;
      right: 1em;
      width: auto;
    }
  }


  &.compare-popup-error {
    width: 404px;
    margin: -39px 0 0 -202px;
    transform: none;
  }
}
