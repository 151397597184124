@import "../vars";

.vote-block {

	.flag {
		position: relative;

		&:before {
			content: '\f00c';
			color: $textColor;
			font: normal 13px/1 "FontAwesome";
			margin: 0 0 0 5px;
		}
	}


	& > .vote-block__box {
		padding: 1em 2em;

		&.voted {
			.vote-block__box-innr {
				width: 100%;
			}
		}

		.vote-block__box-innr {
			list-style: none;
			margin: 0 0 1em;

			& > li {

				& > label {
					color: $textColor;
					font-size: 15px;
					position: relative;
					padding: 0 0 0 1.5em;
				}
			}
		}


		.vote-block__otvet-var {
			color: $textColor;
			font-size: 13px;
			position: relative;
			line-height: 13px;
			padding: 5px 70px 5px 0;
		}

		.vote-block__otvet_-var-percent {
			color: #fff;
			font-size: 12px;
			position: absolute;
			right: 5px;
			bottom: -15px;
		}

		.vote-block__line {
			height: 16px;
			background: #999;
			margin: 0 0 5px;
		}

		.vote-block__line-in {
			height: 16px;
			background: $linkColor;
			width: 0;
			will-change: width;
			transition: all 0.5s ease-in;
		}

		.vote-block__total {
			color: $textColor;
			font-size: 12px;
			padding-top: 10px;
		}
		.vote-block__total-percent {
			bottom: 5px;
		}
	}

	.vote-block__title {
		color: #000;
		font-weight: bold;
		font-size: 15px;
		margin: 0 0 1em;
	}
}



.vote-radio {
	@extend .radio;
}


