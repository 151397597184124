@import "vars";

.module-inner,
.module {
	background: #eeeeee;
	overflow: hidden;
}

.module-header {
	background: #fff;
	cursor: default;
	padding: 26px 1em 19px;
	position: relative;
	text-align: center;
	user-select: none;

	& > span {
		color: #475055;
		font-size: 18px;
		line-height: 1;
		text-transform: uppercase;
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.module-inner {
	background: none;
	margin: 0 0 3em;
}

.module {
	.module-header {
		text-align: left;
	}
}

.module-inner {

	&.special {
		background: #eeeeee;

		.module-header {
			background: none;
		}
	}


	& > .news-goods,
	& > .special {
		padding: 0 1em 1em;
		opacity: 0;
		width: 100%;

		.product-labels {
			top: 0.5em;
		}

		&.owl-loaded {
			opacity: 1;
		}

		.catalog-item {
			.compare {
				display: none;
			}
		}
	}
	

	&.catalog {
		margin: 0 auto;
		max-width: 1200px;
		width: 100%;


		& > .catalog {
			padding: 0 1em;
		}
	}

	&.maybe-you-need {
		margin: 2em 0 0;
	}


}


