@import "vars";


.search {
	float: right;
	padding: 0 1em;
	width: 21%;
}


.search-form {
	position: relative;

	.search-input {
		color: $textColor;
		padding: 7px 71px 7px 12px;
		width: 100%;
	}


	.search-form__button {
		background: transparent;
		display: inline-block;
		color: $textColor;
		line-height: 31px;
		margin: 1px;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		text-transform: none;
		width: 5em;
		z-index: 90;


		&.disabled {
			background: none;
			color: $textColor;
			cursor: default;
		}


		&:not(.disabled) {
			&:hover {
				background: transparent;
				color: $linkColor;
			}
		}

	}

}


.price-block {
	color: $linkColor;
	font-size: 16px;
	
	& > span {
		color: $black;
		font-size: 20px;
		font-weight: bold;
	}

}

.main_src_text {
	min-height: 95px;
}

.search_box .buy_link {
	float: none;
	display: inline-block;
}

.content_search img {
	margin: 0 10px 10px 0;
}

.content_search h3 {
	margin: 0;
	font-size: 14px;
}

.content_search  div.search_box {
	margin: 0 0 15px;
}

.content_search cite {
	margin: 2px 0;
	font-style: normal;
	color: #ca0d1f;
}

.content_search p {
  margin: 0;
}

.search_result {
	position:absolute;
	top: 30px;
	left: 0;
	margin: 4px 0 0;
	background: #fff;
	border-radius: 5px;
	width: 300px;
	font-size: 11px;
	line-height: 16px;
	z-index: 1500;
	text-align: left;
}

.search_result > div {
	box-shadow: 1px 1px 5px #666;
	border: 1px solid #C6C6C6;
}

.search_result > div > .search_box {
	padding:5px 5px;
	border-top: 1px solid #C6C6C6;
	cursor:pointer;
}

.search_result > div > .search_box p {
	margin: 0;
}

.search_result > div > .search_box:hover {
	background:#eee;
}
.search_result > div > .search_box:first-child {
	padding:2px 5px;
	border-top: none;
}

.search_result > div > .search_box img {
	margin: 0 10px 0;
}

span.search_sell {
	color:#000;
	background:#ffff00;
}

.ui-autocomplete-category {
  font-weight: bold;
  padding: 0.2em 0.4em;
  margin: 0.8em 0 0.2em;
  line-height: 1.5;
  font-size: 16px;
}

 .ui-autocomplete-category:first-child {
  padding: 0 0.4em !important;
  margin: 0 0 0.2em !important;
}

.ui-state-focus {
 	background: #eee !important;
 	border: none !important;
}

.ui-autocomplete-category {
 	border-bottom: 1px solid $borderColor;
}

.ui-menu-item {
 		margin: 0 !important;
 		padding: .4em 1em !important;
 		width: auto !important;
}

 .ui-autocomplete {
 	z-index: 100000 !important;
 }

 .top-menu_search{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
}


.img_search .search_form > .search_input {
	padding-right: 45px;
}

.img_search .search_form > .search_button {
	width: 45px;
    height: 55px;
    background: url('../images/img_search.png') 50% -6px no-repeat;
    color: #fff;
}

.ui-menu-item .ui-menu-item-wrapper {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


