@charset "UTF-8";
@font-face {
  font-family: 'FontAwesome';
  src: url("/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.6.3");
  src: url("/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("/assets/fonts/latoregular/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latoregular/Lato-Regular.woff") format("woff"), url("/assets/fonts/latoregular/Lato-Regular.ttf") format("truetype"), url("/assets/fonts/latoregular/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("/assets/fonts/latobold/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latobold/Lato-Bold.woff") format("woff"), url("/assets/fonts/latobold/Lato-Bold.ttf") format("truetype"), url("/assets/fonts/latobold/Lato-Bold.svg#Lato-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("/assets/fonts/latolight/Lato-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latolight/Lato-Light.woff") format("woff"), url("/assets/fonts/latolight/Lato-Light.ttf") format("truetype"), url("/assets/fonts/latolight/Lato-Light.svg#Lato-Light") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("/assets/fonts/latoitalic/Lato-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/latoitalic/Lato-Italic.woff") format("woff"), url("/assets/fonts/latoitalic/Lato-Italic.ttf") format("truetype"), url("/assets/fonts/latoitalic/Lato-Italic.svg#Lato-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/* Forms
   ========================================================================== */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Restore the font weight unset by the previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.owl-carousel {
  display: none;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  visibility: hidden;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    height: 100%;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
    -webkit-touch-callout: none;
    will-change: transform; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    user-select: none; }
  .owl-carousel .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 0; }
    .owl-carousel .owl-nav.disabled {
      display: none; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next {
      color: #fff;
      font-family: "FontAwesome";
      font-size: 1.5em;
      color: #fff;
      cursor: pointer;
      font-size: 4em;
      line-height: 1;
      opacity: 0.73;
      padding: 0 12px;
      position: absolute;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      user-select: none; }
      .owl-carousel .owl-nav .owl-prev:hover,
      .owl-carousel .owl-nav .owl-next:hover {
        opacity: 1; }
    .owl-carousel .owl-nav .owl-prev {
      left: 0.3em;
      right: auto; }
    .owl-carousel .owl-nav .owl-next {
      left: auto;
      right: 0.3em; }
  .owl-carousel .owl-dots {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0.3em;
    padding: 0.21426em 1em 0;
    position: absolute;
    left: 50%;
    bottom: 1em;
    transform: translateX(-50%);
    z-index: 100; }
    .owl-carousel .owl-dots.disabled {
      display: none; }
    .owl-carousel .owl-dots > .owl-dot {
      display: inline-block;
      zoom: 1; }
      .owl-carousel .owl-dots > .owl-dot.active > span {
        background: #193050;
        background: rgba(25, 48, 80, 0.2);
        border: 0.35643em solid #fff;
        padding: 0.28568em;
        margin: 0.35711em 0.28568em;
        width: 1.18em;
        height: 1.18em; }
      .owl-carousel .owl-dots > .owl-dot > span {
        border: 0.2851em solid transparent;
        margin: 0.71427em 0.64283em;
        background: #fff;
        display: block;
        transition: opacity 200ms ease;
        border-radius: 2.142854em; }
  .owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both; }
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  .owl-carousel .fadeOut {
    animation-name: fadeOut; }
  .owl-carousel .owl-height {
    transition: height 500ms ease-in-out; }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }
  .owl-carousel.owl-loaded {
    display: block;
    visibility: visible; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

.desktop .slider-block .slider {
  position: relative; }

.slider-block {
  position: relative; }

.slider {
  overflow: hidden; }
  .slider.owl-carousel .slider-item {
    position: relative; }
    .slider.owl-carousel .slider-item > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .slider.owl-carousel .owl-nav .owl-prev {
    left: 0; }
  .slider.owl-carousel .owl-nav .owl-next {
    right: 0; }
  .slider.owl-carousel .item-label {
    position: absolute;
    bottom: 20%;
    left: 10%;
    will-change: transform; }
    .slider.owl-carousel .item-label > .item-label__link {
      display: block;
      text-decoration: none; }
      .slider.owl-carousel .item-label > .item-label__link > h2 {
        background: rgba(32, 95, 22, 0.63);
        color: #fff;
        font-size: 36px;
        font-weight: normal;
        padding: 6px 11px 4px;
        line-height: 1;
        margin: 0 0 0.5em;
        text-transform: uppercase; }
      .slider.owl-carousel .item-label > .item-label__link > p {
        background: rgba(235, 217, 22, 0.63);
        color: #000;
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        padding: 3px 5px 1px; }

.no-js .owl-carousel {
  display: block; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
input::-ms-clear {
  display: none; }

body {
  background: #fff;
  font: normal 16px/1.5 "Lato", Arial, sans-serif;
  color: #515151;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  -webkit-font-smoothing: antialiased;
  transition: background 0.05s ease; }

.lock body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

table {
  border-collapse: collapse; }

ul {
  margin-top: 0;
  margin-bottom: 0; }

h1 {
  color: #49281f;
  font-size: 28px; }

h2 {
  color: #49281f;
  font-size: 24px; }

h3 {
  color: #49281f;
  font-size: 21px; }

h1, h2, h3, h4, h5, h6, p {
  padding: 0 0 0.5em; }

a {
  color: #347ea7;
  transition: color 0.2s ease;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2); }

a:hover {
  color: #79b9dc;
  transition: color 0.2s ease;
  text-decoration: underline; }

::-webkit-input-placeholder {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 18px; }

::-moz-placeholder {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 18px; }

:-moz-placeholder {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 18px; }

:-ms-input-placeholder {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 18px; }

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  width: 100%; }

.alert {
  color: red; }

.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center; }

.noscript_alert > span {
  font-weight: bold;
  color: red; }

.content-inner {
  font-size: 1em; }

.content-inner h1 {
  color: #347ea7;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none; }

.content-inner .title {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: bold; }

.path {
  color: #347ea7;
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 1em;
  padding: 1em 0 2em;
  position: relative; }
  .path:before {
    background: linear-gradient(to right, #c9c3ba 0%, #c9c3ba 15%, rgba(255, 255, 255, 0) 70%);
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%; }

.inner .path {
  background: none; }

.content {
  margin: 1em 0 0;
  position: relative; }
  .content img {
    height: auto;
    max-width: 800px;
    width: 100%; }

main {
  position: relative; }

button,
.button {
  background: #347ea7;
  border: none;
  border-radius: 0;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  font: normal 13px/100% "Arial", Helvetica, sans-serif;
  outline: none;
  padding: 11px 1em 10px;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }
  button:hover,
  .button:hover {
    background: #00527f;
    color: #fff;
    transition: background 0.2s ease;
    text-decoration: none; }
  button:active,
  .button:active {
    background: #345460;
    color: #fff;
    text-decoration: none; }
  button.disabled,
  .button.disabled {
    background: #868687;
    color: #eee;
    cursor: default; }

.desktop button,
.desktop .button {
  transition: background 0.3s ease, border 0.3s ease, color 0.2s ease; }

.input-text,
.input-textarea {
  background: #efefef;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 0;
  color: #515151;
  font-size: 13px;
  font-style: normal;
  line-height: 17px;
  padding: 7px 1em;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.yashare_text {
  text-align: right; }

html.ie9 * {
  border-radius: 0 !important; }

.call-order-wrapper > .button {
  padding: 11px 24px 12px 39px;
  position: relative; }
  .call-order-wrapper > .button:before {
    background: url("/assets/images/sprite.png") 3px 2px no-repeat #efda9e;
    content: '';
    border-radius: 50%;
    display: block;
    border: 1px solid #382507;
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px; }

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 900; }

.locked .overlay {
  opacity: 1;
  transition: opacity 0.2s ease;
  visibility: visible; }

.mob-icon {
  font: normal 1.5em/1.2 "FontAwesome";
  position: absolute;
  visibility: hidden; }

.mobile .mob-icon,
.tablet .mob-icon {
  visibility: visible; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.pollitra > div {
  display: inline-block;
  vertical-align: top;
  margin: 5px 0 3px 5px; }

.pollitra input {
  display: none; }

.pollitra label {
  display: block;
  border: 3px solid transparent; }

.pollitra label span {
  height: 24px;
  width: 24px;
  display: block;
  cursor: pointer;
  margin: 1px; }

.pollitra input:checked + label {
  border-color: #AAAAFF; }

.citronLoader {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2; }

.citronLoader span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  background: url(../images/ajaxloader.gif) 0 0 no-repeat; }

.citronLoaderBig {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2; }

.citronLoaderBig span {
  position: absolute;
  top: 100px;
  left: 48%;
  width: 66px;
  height: 66px;
  background: url(../images/citronloader.gif) 0 0 no-repeat; }

#ajaxedsearch {
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.content-block.filtrovannoe {
  padding: 10px; }

.icon {
  cursor: default;
  display: block;
  position: absolute;
  height: 24px;
  width: 24px;
  left: -1.333em;
  top: 0.5em;
  text-align: center; }
  .icon:before {
    content: '';
    font: normal 1em/1 "FontAwesome";
    color: #000;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.5em;
    z-index: 2; }
  .icon > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    z-index: 4; }

.prd_pages_bottom,
.prd_pages_top {
  color: #999;
  font-size: 13px;
  text-align: right; }
  .prd_pages_bottom > a,
  .prd_pages_top > a {
    display: inline-block;
    padding: 0 4px; }
    .prd_pages_bottom > a.sel_page,
    .prd_pages_top > a.sel_page {
      color: #515151;
      text-decoration: none; }

.tablet body,
.mobile body,
.desktop body {
  opacity: 1;
  transition: opacity 0.4s ease;
  visibility: visible; }

.gallery-photo {
  margin: 1em 0 0;
  padding: 0 2em; }
  .gallery-photo .owl-nav.disabled {
    display: block; }
  .gallery-photo .owl-nav .owl-next {
    right: -0.75em; }
  .gallery-photo .owl-nav .owl-prev {
    left: -0.75em; }
  .gallery-photo .owl-nav .owl-prev,
  .gallery-photo .owl-nav .owl-next {
    color: #30a12a;
    font-size: 3em;
    font-weight: bold;
    opacity: 0.5; }
    .gallery-photo .owl-nav .owl-prev:hover,
    .gallery-photo .owl-nav .owl-next:hover {
      opacity: 1;
      transition: opacity 0.2s ease; }
  .gallery-photo .photo-item > a > img {
    display: block;
    height: auto;
    max-width: 240px;
    width: 100%; }

.photo_block,
.album-block {
  float: left;
  margin: 0 1em 1em 0;
  width: calc(25% - 0.75em); }
  .photo_block:nth-child(4n + 4),
  .album-block:nth-child(4n + 4) {
    margin: 0 0 1em; }
  .photo_block .photo_photo > a > img,
  .photo_block .album-photo > a > img,
  .album-block .photo_photo > a > img,
  .album-block .album-photo > a > img {
    display: block;
    height: auto;
    max-width: 360px;
    width: 100%; }
  .photo_block .album-title,
  .album-block .album-title {
    padding: 0 0.5em;
    margin: 0.5em 0 0;
    text-align: center; }
    .photo_block .album-title > a,
    .album-block .album-title > a {
      font-size: 14px;
      margin: 0.5em 0; }

.photos_text {
  font-size: 14px; }

.search_box .search-text {
  font-size: 14px;
  line-height: 1.333; }

.search_box img {
  margin: 0 1em 4em 0 !important;
  max-width: 170px; }

.example {
  background: #347ea7;
  color: #fff;
  font-weight: 700;
  display: inline-block; }

.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 73%; }
  .video-responsive iframe,
  .video-responsive object,
  .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.redz {
  color: #df141e; }

.radio, .vote-radio {
  width: 15px;
  height: 15px; }
  .radio + label, .vote-radio + label {
    cursor: pointer; }
  .radio:not(checked), .vote-radio:not(checked) {
    position: absolute;
    opacity: 0; }
    .radio:not(checked) + label, .vote-radio:not(checked) + label {
      position: relative;
      padding: 0 0 0 35px; }
      .radio:not(checked) + label:before, .vote-radio:not(checked) + label:before {
        border-radius: 50%;
        background: #FFF;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px; }
      .radio:not(checked) + label:after, .vote-radio:not(checked) + label:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #347ea7;
        border: 1px solid #fff;
        opacity: 0;
        transition: all .2s; }
  .radio:checked + label:after, .vote-radio:checked + label:after {
    opacity: 1; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.header-top {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%; }
  .header-top .logo {
    float: left;
    padding: 0 1em;
    width: 24%; }
    .header-top .logo > a {
      display: block; }
  .header-top .timeswork-block {
    float: left;
    padding: 0 1em;
    width: 20%; }
  .header-top .contacts {
    float: left;
    padding: 0 1em;
    width: 40%; }
  .header-top .header-cart {
    float: right;
    padding: 0 1em;
    position: relative;
    width: 16%; }

.header-top {
  padding: 2.5em 0 2em; }
  .header-top .logo img {
    display: block;
    height: auto;
    max-width: 246px;
    width: 100%; }
  .header-top .phones {
    display: inline-block;
    vertical-align: top; }
  .header-top .phones-list {
    display: inline-block;
    list-style: none;
    position: relative; }
    .header-top .phones-list .icon {
      margin-top: -7px; }
      .header-top .phones-list .icon:before {
        content: '\f095'; }
  .header-top .phones-list__item {
    display: inline-block;
    margin: 0 0.5em 0 0; }
    .header-top .phones-list__item:last-child {
      margin: 0; }
    .header-top .phones-list__item > a {
      color: #222;
      cursor: default;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap; }
      .header-top .phones-list__item > a .pref {
        font-weight: normal; }
      .header-top .phones-list__item > a:hover {
        color: #222; }
    .header-top .phones-list__item > span {
      color: #979797;
      display: block;
      font-size: 12px;
      line-height: 1;
      text-align: left; }
  .header-top .mail {
    display: inline-block;
    padding: 0 0 0 4em;
    vertical-align: top; }
  .header-top .contacts {
    position: relative;
    text-align: center; }
  .header-top .mail-block {
    position: relative; }
    .header-top .mail-block .icon {
      height: 19px;
      width: 23px;
      top: 0.25em; }
      .header-top .mail-block .icon:before {
        content: '\f0e0'; }
    .header-top .mail-block > span {
      color: #979797;
      display: block;
      font-size: 12px;
      line-height: 1;
      margin: 0 0 0 5px;
      text-align: left; }
    .header-top .mail-block > a {
      color: #222;
      font-size: 14px;
      line-height: 1;
      margin: 0 0 0 5px;
      text-align: left; }
      .header-top .mail-block > a:hover {
        color: #347ea7; }
  .header-top .timeswork-block {
    padding: 0 1em 0 1.5em;
    position: relative; }
    .header-top .timeswork-block:before {
      content: '\f017';
      color: #000;
      font: normal 14px/1 "FontAwesome";
      position: absolute;
      top: 3px;
      left: 0; }
  .header-top .time-wrap {
    float: left;
    width: 50%;
    font-weight: bold;
    font-size: 14px; }
    .header-top .time-wrap > span {
      color: #979797;
      display: block;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.5; }
      .header-top .time-wrap > span > i {
        font-size: 10px;
        font-style: normal; }
      .header-top .time-wrap > span > sup {
        font-size: 8px; }
  .header-top .search {
    position: relative; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%; }

main {
  overflow: hidden;
  width: 100%; }
  main > .container {
    padding: 1em 0 0; }

aside {
  float: left;
  padding: 0 1em 3em;
  width: 270px; }

.content-block {
  float: right;
  min-height: calc(100vh - 641px);
  padding: 0 1em;
  margin: 0 0 6em;
  width: calc(100% - 270px); }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.nav-block {
  border-top: 1px solid #dcdcdc;
  padding: 1em 0; }

nav {
  float: left;
  padding: 0 0 0 1em;
  width: 79%; }
  nav .menu_level_1 {
    display: table;
    list-style: none;
    height: 31px; }
    nav .menu_level_1.active {
      overflow: visible; }
    nav .menu_level_1 > li {
      display: table-cell;
      position: relative;
      text-align: center; }
      nav .menu_level_1 > li > a {
        color: #515151;
        font-size: 16px;
        display: block;
        padding: 4px 0.5em;
        position: relative;
        text-decoration: none;
        text-align: center;
        user-select: none;
        white-space: nowrap; }
        nav .menu_level_1 > li > a:before {
          background: #347ea7;
          content: '';
          position: absolute;
          top: -16px;
          left: 0.5em;
          right: 0.5em;
          visibility: hidden;
          height: 2px;
          opacity: 0;
          z-index: 2; }
      nav .menu_level_1 > li:not(.selected):hover > a {
        color: #347ea7; }
        nav .menu_level_1 > li:not(.selected):hover > a:before {
          background: #347ea7;
          opacity: 1;
          height: 4px;
          transition: opacity 0.2s ease;
          visibility: visible; }
      nav .menu_level_1 > li.subcat.selected > a {
        cursor: pointer; }
      nav .menu_level_1 > li.subcat > a {
        padding: 3px 2em 7px 1em; }
      nav .menu_level_1 > li.subcat:before {
        color: #515151;
        content: '\f107';
        font: normal 22px/1 "FontAwesome";
        position: absolute;
        right: 0.5em;
        margin-top: -14px;
        top: 50%; }
      nav .menu_level_1 > li.subcat:hover .menu_level_2 {
        opacity: 1;
        transition: all 0.3s ease;
        visibility: visible; }
      nav .menu_level_1 > li.subcat:hover:before {
        color: #347ea7;
        margin-top: -10px; }
      nav .menu_level_1 > li.selected > a {
        color: #347ea7;
        cursor: default; }
        nav .menu_level_1 > li.selected > a:before {
          background: #347ea7;
          opacity: 1;
          transition: opacity 0.2s ease;
          visibility: visible; }
    nav .menu_level_1 a {
      display: block;
      text-decoration: none; }
  nav .menu_level_2 {
    background: rgba(246, 246, 246, 0.9);
    box-shadow: 1px 2px 7px -2px #ccc;
    display: table;
    list-style: none;
    margin: 0;
    padding: 1em 1.5em;
    position: absolute;
    opacity: 0;
    top: 100%;
    left: 0;
    z-index: 99999;
    text-align: left;
    text-transform: none;
    visibility: hidden;
    width: 100%; }
    nav .menu_level_2 > li {
      display: table-row;
      padding: 0; }
      nav .menu_level_2 > li:not(.selected) > a:hover {
        color: #347ea7;
        text-decoration: underline; }
      nav .menu_level_2 > li.selected > a {
        color: #347ea7;
        text-decoration: none; }
        nav .menu_level_2 > li.selected > a.empty {
          cursor: default; }
      nav .menu_level_2 > li > a {
        color: #515151;
        display: block;
        font-size: 14px;
        padding: 7px 0;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  nav .menu_level_3 {
    display: block;
    list-style-type: disc;
    margin: 0 2em 0 1em; }
    nav .menu_level_3 > li > a {
      display: block;
      color: #eee; }
    nav .menu_level_3 > li:not(.selected) > a:hover {
      color: #e55727;
      text-decoration: underline; }
    nav .menu_level_3 > li.selected > a {
      color: #e55727;
      text-decoration: none; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.nav-left {
  position: relative; }
  .nav-left .menu_level_1 {
    display: block; }
    .nav-left .menu_level_1 > li {
      border-bottom: 1px solid #cccccc;
      list-style: none;
      transition: all 0.3s ease;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
      .nav-left .menu_level_1 > li:last-child {
        border-bottom: none; }
      .nav-left .menu_level_1 > li > a {
        display: block;
        color: #475055;
        font-size: 16px;
        line-height: 1.1;
        padding: 21px 1em 19px 2em;
        position: relative;
        text-decoration: none;
        transition: color 0.3s ease; }
        .nav-left .menu_level_1 > li > a:after {
          content: '\f105';
          color: #347ea7;
          font: normal 16px/1 "FontAwesome";
          position: absolute;
          margin-top: -9px;
          top: 50%;
          left: 1em;
          transform: translate3d(0, 0, 0); }
      .nav-left .menu_level_1 > li:not(.selected):hover {
        background: #347ea7;
        border-bottom-color: #347ea7; }
        .nav-left .menu_level_1 > li:not(.selected):hover > a {
          color: #fff;
          text-decoration: none; }
          .nav-left .menu_level_1 > li:not(.selected):hover > a:after {
            color: #fff;
            transform: translate3d(5px, 0, 0);
            transition: transform 0.2s ease-out, color 0.2s ease-in; }
      .nav-left .menu_level_1 > li.selected {
        background: #347ea7;
        cursor: default;
        user-select: none; }
        .nav-left .menu_level_1 > li.selected > a {
          color: #fff;
          text-decoration: none; }
          .nav-left .menu_level_1 > li.selected > a:after {
            color: #fff;
            transform: rotate(90deg); }
        .nav-left .menu_level_1 > li.selected.subcats_exists:after {
          color: #fff;
          transform: rotate(90deg); }
  .nav-left .menu_level_2 {
    background: #eeeeee;
    display: block;
    padding: 8px 0; }
    .nav-left .menu_level_2 > li {
      list-style: none;
      padding: 0 0 0 2.5em; }
      .nav-left .menu_level_2 > li > a {
        color: #382507;
        display: block;
        font-size: 16px;
        padding: 3px 5px 2px;
        position: relative;
        text-decoration: none; }
      .nav-left .menu_level_2 > li:not(.selected):hover > a {
        color: #347ea7;
        text-decoration: underline; }
      .nav-left .menu_level_2 > li.selected > a {
        color: #347ea7;
        text-decoration: none; }
        .nav-left .menu_level_2 > li.selected > a:after {
          background: #347ea7; }
  .nav-left .menu_level_3 {
    background: #f1f1f1;
    border-top: 1px solid #d7d7d7;
    display: none;
    padding: 0.5em 1em 0.75em 2em; }
    .nav-left .menu_level_3 > li {
      list-style: disc;
      padding: 0.2em 0; }
      .nav-left .menu_level_3 > li > span > a {
        color: #666;
        display: block;
        font-size: 0.86em;
        text-decoration: none; }
      .nav-left .menu_level_3 > li.selected {
        color: #1c8db1; }
      .nav-left .menu_level_3 > li:hover > span > a {
        color: #1c8db1; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.module-inner,
.module {
  background: #eeeeee;
  overflow: hidden; }

.module-header {
  background: #fff;
  cursor: default;
  padding: 26px 1em 19px;
  position: relative;
  text-align: center;
  user-select: none; }
  .module-header > span {
    color: #475055;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.module-inner {
  background: none;
  margin: 0 0 3em; }

.module .module-header {
  text-align: left; }

.module-inner.special {
  background: #eeeeee; }
  .module-inner.special .module-header {
    background: none; }

.module-inner > .news-goods,
.module-inner > .special {
  padding: 0 1em 1em;
  opacity: 0;
  width: 100%; }
  .module-inner > .news-goods .product-labels,
  .module-inner > .special .product-labels {
    top: 0.5em; }
  .module-inner > .news-goods.owl-loaded,
  .module-inner > .special.owl-loaded {
    opacity: 1; }
  .module-inner > .news-goods .catalog-item .compare,
  .module-inner > .special .catalog-item .compare {
    display: none; }

.module-inner.catalog {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%; }
  .module-inner.catalog > .catalog {
    padding: 0 1em; }

.module-inner.maybe-you-need {
  margin: 2em 0 0; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.category-list {
  margin: 2em 0 1em; }
  .category-list > .category-list__item {
    float: left;
    margin: 0 1em 1em 0;
    overflow: hidden;
    position: relative;
    width: calc(25% - 0.75em); }
    .category-list > .category-list__item:nth-child(4n + 4) {
      margin: 0 0 1em; }
    .category-list > .category-list__item > a {
      display: inline-block;
      max-width: 162px;
      text-decoration: none; }
      .category-list > .category-list__item > a > .pic {
        border: 1px solid #c8c8c8;
        display: block;
        position: relative;
        text-align: center; }
        .category-list > .category-list__item > a > .pic > img {
          max-width: 162px;
          display: block;
          margin: 0 auto; }
        .category-list > .category-list__item > a > .pic > .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
      .category-list > .category-list__item > a:hover .category-list__item-title {
        color: #79b9dc;
        transition: color 0.2s ease; }
      .category-list > .category-list__item > a:hover .bg {
        background: rgba(0, 0, 0, 0.3);
        transition: background 0.2s ease; }
    .category-list > .category-list__item .category-list__item-title {
      color: #347ea7;
      display: block;
      font-size: 16px;
      height: 48px;
      line-height: 1.25;
      padding: 0.25em 0;
      text-decoration: none;
      text-align: center; }
  .category-list.brands > .category-list__item {
    text-align: center; }

.flexbox .category-list__item .category-list__item-title {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.product .product_left_block {
  float: left;
  overflow: hidden;
  padding: 0 2em 0 0;
  position: relative;
  width: 37%; }

.product .product_mid_block {
  float: right;
  margin: 1.2em 0 0;
  padding: 0 0 0 2em;
  width: 63%; }

.compare input[type=checkbox] {
  cursor: pointer; }

.compare .compare_link,
.compare label {
  cursor: pointer;
  font-size: 12px;
  vertical-align: text-bottom; }

.product-labels {
  position: absolute;
  pointer-events: none;
  top: 2em;
  left: 0; }
  .product-labels > span {
    display: block;
    color: #fff;
    cursor: default;
    margin: 0 0 6px;
    padding: 0;
    position: relative;
    font: normal 12px/24px "Arial", "Helvetica", sans-serif;
    text-align: center; }
    .product-labels > span:after {
      border-top: 12px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      right: -10px;
      bottom: 0; }
    .product-labels > span.label-new {
      background: #347ea7;
      width: 65px; }
      .product-labels > span.label-new:after {
        border-top-color: #347ea7;
        border-bottom-color: #347ea7; }
    .product-labels > span.label-hit {
      background: #29e4e5;
      width: 38px; }
      .product-labels > span.label-hit:after {
        border-top-color: #29e4e5;
        border-bottom-color: #29e4e5; }
    .product-labels > span.label-spec {
      background: #e52929;
      width: 57px; }
      .product-labels > span.label-spec:after {
        border-top-color: #e52929;
        border-bottom-color: #e52929; }

.sort_panel {
  background: #eeeeee;
  color: #515151;
  font-weight: 100;
  font-size: 15px;
  padding: 10px 1em 8px;
  margin: 3em 0 1em; }
  .sort_panel > span {
    margin: 0 0.5em;
    position: relative; }
    .sort_panel > span:after {
      content: '';
      color: #347ea7;
      font: normal 14px/1 "FontAwesome"; }
    .sort_panel > span > a {
      margin: 0 3px 0 0; }
    .sort_panel > span.this_sort {
      font-weight: bold; }
    .sort_panel > span.desc_sort:after {
      content: "\f161"; }
    .sort_panel > span.asc_sort:after {
      content: "\f160"; }

.product .short_description {
  margin: 1em 0 0;
  max-width: 400px; }
  .product .short_description * {
    color: #515151;
    font-size: 13px;
    line-height: 1.333; }

.product .rate_results._get {
  pointer-events: all;
  z-index: 20; }

.product .rate_results:before {
  color: #222;
  top: -4px;
  pointer-events: none;
  right: -37px; }

.product .rating-stars {
  position: relative;
  z-index: 3; }

.product .dop_atr {
  display: inline-block;
  padding: 0 0 1em;
  min-width: 240px; }

.product .price-block {
  max-width: 400px; }

.product .characters-table__row {
  color: #222;
  font-size: 14px; }
  .product .characters-table__row > b {
    font-weight: normal; }

.product .characters-table {
  max-width: 400px; }
  .product .characters-table .characters-table__row {
    color: #222;
    line-height: 1.75;
    position: relative; }
    .product .characters-table .characters-table__row:before {
      border-bottom: 1px dashed #bababa;
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 0; }
    .product .characters-table .characters-table__row .rate_results {
      min-width: auto;
      position: static;
      vertical-align: middle; }
      .product .characters-table .characters-table__row .rate_results:before {
        padding: 4px 0;
        min-width: 174px;
        left: auto;
        top: -6px;
        text-align: right; }
      .product .characters-table .characters-table__row .rate_results > b {
        font-weight: normal; }
    .product .characters-table .characters-table__row a {
      color: #347ea7; }
      .product .characters-table .characters-table__row a:hover {
        color: #79b9dc; }
    .product .characters-table .characters-table__row > b {
      float: left;
      padding: 0 1em 0 0; }
    .product .characters-table .characters-table__row > span {
      float: right;
      padding: 0 0 0 1em; }
    .product .characters-table .characters-table__row > span,
    .product .characters-table .characters-table__row > b {
      background: #fff;
      position: relative;
      z-index: 10; }
    .product .characters-table .characters-table__row > b {
      font-weight: normal; }

.product .prod_dop_option.rating_block {
  color: #767676;
  font: 400 15px/1 Arial,Helvetica,sans-serif;
  max-width: 70%; }
  .product .prod_dop_option.rating_block .rate_results {
    color: #222;
    font-size: 13px;
    float: right;
    padding: 5px 0 5px 10px; }
  .product .prod_dop_option.rating_block > b {
    color: #222;
    font-weight: normal;
    line-height: 1.75;
    position: relative;
    z-index: 10; }

.product .product_top {
  margin: 0 0 2em; }

.product .prod_title {
  color: #347ea7;
  font-size: 18px;
  line-height: 1.25;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-transform: none; }

.product .product_photo img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.product .product_photo > a {
  display: block;
  border: 1px solid #d5d4e5;
  overflow: hidden; }

.product .kolvo_buy_button_add {
  margin: 0 0 1em; }

.product .prod-bnts {
  margin: 0.5em 0 0;
  max-width: 400px; }
  .product .prod-bnts .button {
    display: block;
    font-size: 16px;
    text-align: center;
    text-transform: none; }
  .product .prod-bnts .fast_order {
    margin: 1em 0 0; }

.product .product-prod_prices {
  font-size: 32px; }

.product .prod_price_old {
  color: #626262;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  text-decoration: line-through;
  position: relative; }

.product .prod_big_price {
  display: inline-block;
  color: #222;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0.5em 0 0; }

.product.--hidden {
  display: none; }

.product_dop_photo_slider .owl-carousel {
  padding: 0 1px 0 0; }

.product_dop_photo_slider .dop-photo__thumb {
  border: 1px solid #d5d4e5;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2); }
  .product_dop_photo_slider .dop-photo__thumb > img {
    cursor: pointer;
    display: block; }

.product_dop_photo_slider > .owl-carousel {
  margin: 1em 0 0; }
  .product_dop_photo_slider > .owl-carousel .owl-stage-outer {
    padding: 0 0 1px; }
  .product_dop_photo_slider > .owl-carousel > .owl-nav {
    perspective-origin: center; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev,
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next {
      height: 46px;
      padding: 0;
      position: absolute;
      width: 15px;
      top: 0;
      text-indent: -9999px; }
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev:hover:before,
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next:hover:before {
        background-position: -15px -339px; }
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev:before,
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next:before {
        background: url("/assets/images/sprite.png") 0 -339px no-repeat;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev {
      left: 0; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next {
      right: 0;
      top: -23px;
      transform: rotate(180deg); }

.product_dop_modes {
  position: relative;
  font-size: 14px;
  margin: 0 0 2em; }
  .product_dop_modes .product_dop_modes_content {
    background: #fff;
    position: relative;
    z-index: 10;
    padding: 1em 0;
    text-align: justify;
    margin: 0 0 1em; }
    .product_dop_modes .product_dop_modes_content li,
    .product_dop_modes .product_dop_modes_content p {
      color: #382507;
      font-size: 14px; }
    .product_dop_modes .product_dop_modes_content a {
      font-size: 14px; }
    .product_dop_modes .product_dop_modes_content .product_dop_title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px; }

.product_dop_modes_navigation {
  background: #f4f4f4;
  position: relative;
  height: 35px;
  padding: 0;
  z-index: 12; }
  .product_dop_modes_navigation li {
    border-top: 2px solid transparent;
    position: relative;
    display: block;
    float: left;
    font-size: 14px;
    color: #475055;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    cursor: pointer; }
    .product_dop_modes_navigation li.mod_selected {
      border-top: 2px solid #347ea7;
      cursor: default;
      color: #347ea7;
      background: #fff; }

.chars_table {
  table-layout: fixed;
  max-width: calc(320px - 2em);
  width: 100%; }
  .chars_table > tbody > tr {
    border-bottom: 1px solid #bababa; }
  .chars_table .chars_title {
    width: 200px; }

.pr-recommended {
  border: none;
  padding: 10px 0; }

.pr-recommended .product_dop_title {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 10px 25px; }

.product-comments-form {
  float: left;
  padding: 0 1em 0 0;
  width: 300px; }
  .product-comments-form .form_title {
    position: static; }
  .product-comments-form .form_block {
    margin: 0 0 1em; }
  .product-comments-form .input-textarea,
  .product-comments-form .input-text {
    display: block;
    width: 100%; }
  .product-comments-form .input-textarea {
    resize: none;
    height: 7em; }

.fast_order_form--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  display: none; }

.prod_selector {
  float: left;
  margin: 0 0 1.5em;
  width: 60%; }
  .prod_selector .prod_selector_wrapper {
    display: inline-block;
    position: relative; }
    .prod_selector .prod_selector_wrapper:after {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: calc(1em + 3px);
      transform: rotate(-40deg); }
    .prod_selector .prod_selector_wrapper:before {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: 1em;
      transform: rotate(40deg); }
  .prod_selector .select_size {
    background: #fff;
    border: 1px solid #a8a8a8;
    border-radius: 0;
    color: #a8a8a8;
    cursor: pointer;
    min-width: 228px;
    outline: none;
    padding: 3px 1em;
    height: 33px;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important; }
  .prod_selector .prod_selector_title {
    display: inline-block;
    color: #696969;
    font: normal 15px/1 "Arial", "Helvetica", sans-serif;
    margin: 0 5% 0 0; }

select::-ms-expand {
  display: none; }

.prod_selector_title {
  color: #382507;
  font-style: normal;
  font-size: 17px; }

.prod-color-wrapper {
  display: inline-block;
  margin: 0.5em 0 0.5em; }
  .prod-color-wrapper .prod-color-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    text-transform: lowercase; }

.prod_selector_size .size_product {
  background: url("/assets/images/skin.png") no-repeat;
  border-radius: 7px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 51px;
  margin: 0 5px;
  width: 51px; }

.responses_content .message_box {
  float: right;
  width: calc(100% - 300px);
  padding: 0 0 1em 1em;
  margin: 0 0 2em; }

.responses_content .message_top {
  border-bottom: 1px solid #bababa;
  display: table;
  width: 100%; }

.responses_content .message_text {
  margin: 0.5em 0 0; }

.responses_content .message_date,
.responses_content .message_name {
  display: table-cell; }

.responses_content .message_name {
  vertical-align: bottom; }
  .responses_content .message_name > span {
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle; }

.responses_content .message_date {
  float: right;
  text-align: center; }
  .responses_content .message_date > span {
    font-size: 12px;
    color: #a8a8a8;
    padding: 1px 3px 2px; }
    .responses_content .message_date > span.dt {
      background: #347ea7;
      color: #fff;
      display: block; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.compareCategories {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 0 1em; }
  .compareCategories .trashAll {
    margin: 0 5px;
    padding: 4px 10px;
    cursor: pointer;
    background: #fff;
    outline: 1px solid #aaa; }

.comparison,
.comparisonz {
  display: flex; }

.comparison .owl-stage-outer .owl-stage .owl-item {
  display: inline-block;
  vertical-align: top; }

.comparison.owl-carousel .owl-nav .owl-prev,
.comparison.owl-carousel .owl-nav .owl-next {
  background: #e2e2e2;
  border-radius: 50%;
  color: #fff;
  line-height: 0.96em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center; }

.comparison.owl-carousel .owl-nav .owl-prev:hover,
.comparison.owl-carousel .owl-nav .owl-next:hover {
  color: #aaa; }

.comparison.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -0.5em; }

.comparison.owl-carousel .owl-nav .owl-prev {
  left: -0.5em;
  right: auto; }

.comparison.owl-carousel .owl-nav {
  top: 40%; }

.comparison .container {
  padding: 5px 0 0;
  margin: 0 2px;
  width: 220px;
  box-shadow: 0 0 4px -1px #464646;
  background: #fff;
  text-align: center; }

.comparison .container > div.general .removeItem {
  padding: 10px 0;
  cursor: pointer; }

.comparison .container > div.general .numbered {
  width: 30px;
  position: absolute;
  background: #347ea7;
  color: #fff;
  border-radius: 0 0 20px;
  top: 0;
  left: 0;
  padding: 5px; }

.comparison .container > div {
  padding: 10px 0 0;
  margin: 0 0 2px; }

.comparison .container > div .linkTitle {
  text-transform: uppercase;
  padding: 0 0 5px; }

.comparison .container > div .head {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0 4px -1px #464646;
  background: #f7f7f7;
  font-size: 12px;
  cursor: pointer; }

.comparison .container > div .priceOld {
  text-decoration: line-through;
  color: #aaa; }

.comparison .container > div .generalParam:nth-child(odd) {
  background: #ccc; }

.comparison .container > div .generalParam .inners {
  padding: 5px 0; }

.comparison .container > div .generalParam .inners b {
  font-size: 20px;
  line-height: 14px; }

.comparison .container > div .generalParam .param_tsvet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.comparison .container > div .generalParam .param_tsvet .colorPunkt {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 15px; }

.compare_box {
  padding: 0 5px; }

.compare_text {
  align-self: center;
  cursor: pointer;
  font-size: 13px; }

.owl-placeholder {
  border: 1px solid green;
  background-color: white;
  box-shadow: 0 0 10px #bababa; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.catalog-list {
  margin: 2em 0; }

.catalog-item {
  cursor: default;
  padding: 0 0 5.5em;
  margin: 1em 0 0;
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2); }
  .catalog-item .compare {
    text-align: center; }
  .catalog-item .wrappa,
  .catalog-item .product_photo {
    float: none;
    width: 100%; }
  .catalog-item .prod_podrobnee {
    text-align: center; }
  .catalog-item .product_photo {
    border: 1px solid #bababa;
    text-align: center; }
    .catalog-item .product_photo .show_product {
      display: block;
      overflow: hidden; }
      .catalog-item .product_photo .show_product > img {
        display: block;
        height: auto;
        max-width: 400px;
        width: 100%; }
  .catalog-item .catalog-item__title {
    border-bottom: 1px solid #bababa;
    padding: 4px 10px; }
    .catalog-item .catalog-item__title > .show_product {
      color: #347ea7;
      display: block;
      font-size: 16px;
      line-height: 1.25;
      text-decoration: none; }
      .catalog-item .catalog-item__title > .show_product:hover {
        color: #79b9dc; }
  .catalog-item .product-prod_prices {
    float: left;
    position: relative;
    width: 50%; }
  .catalog-item .prod_price_old {
    float: right;
    width: 50%;
    font-size: 14px;
    line-height: 2;
    color: #626262;
    text-align: right;
    text-decoration: line-through; }
  .catalog-item .prod_price {
    color: #222;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap; }
  .catalog-item .prod-bottom-wrapper {
    position: absolute;
    bottom: 0;
    height: 5em;
    left: 0;
    right: 0; }
  .catalog-item .show_product.button {
    margin: 0.5em 0 0; }

.catalog-list > .catalog-item {
  float: left;
  margin: 0 2em 2em 0;
  width: calc(25% - 1.5em); }
  .catalog-list > .catalog-item:nth-child(3n + 4) {
    margin: 0 0 2em; }

.no-prod {
  font-size: 14px;
  clear: both;
  padding: 1em 0 0; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.module.ajaxflowcart {
  background: #eeeeee;
  overflow: visible;
  margin: 0; }

.ajaxflowcart {
  box-shadow: 1px 2px 7px -2px #ccc;
  right: 1em;
  top: 113%;
  padding: 5px;
  margin: 0;
  position: absolute;
  text-align: center;
  width: 350px;
  z-index: 24000; }
  .ajaxflowcart .price {
    color: #347ea7;
    font-size: 20px; }
  .ajaxflowcart .currency {
    color: #515151;
    font-size: 18px; }
  .ajaxflowcart > table {
    width: 100%; }
    .ajaxflowcart > table td {
      padding: 5px;
      border-top: 1px solid #d8d8d8; }
  .ajaxflowcart tr:first-child > td {
    border-top: none; }
  .ajaxflowcart .ajaxflowcart_kill {
    color: #347ea7;
    font-style: normal;
    padding: 0; }
    .ajaxflowcart .ajaxflowcart_kill > span {
      cursor: pointer;
      padding: 0.25em 0.5em; }
  .ajaxflowcart .ajaxflowcart_title {
    text-align: left; }
    .ajaxflowcart .ajaxflowcart_title > .prod-param {
      color: #515151;
      font-size: 12px;
      text-align: left; }
    .ajaxflowcart .ajaxflowcart_title > a {
      font-size: 14px;
      line-height: 1.2;
      display: block; }
  .ajaxflowcart .ajaxflowcart_count {
    color: #515151;
    font-size: 12px;
    white-space: nowrap; }
  .ajaxflowcart .ajaxflowcart_price {
    white-space: nowrap; }
  .ajaxflowcart .ajaxflowcart_summ {
    color: #515151;
    font-size: 14px; }
  .ajaxflowcart .button {
    margin: 5px 0 0; }

.profile-block {
  float: left; }
  .profile-block > form > table {
    table-layout: fixed; }
  .profile-block .reg_col_1,
  .profile-block .reg_col_2 {
    padding: 0 0 0.5em; }
  .profile-block .reg_col_1 {
    padding-right: 1em; }
  .profile-block .reg_col_2 {
    position: relative; }

.change_pass_block {
  float: right; }
  .change_pass_block .change_pass_block--title {
    font-size: 16px;
    padding: 0 0 1em;
    text-align: center; }
  .change_pass_block .reg_col_1,
  .change_pass_block .reg_col_2 {
    padding: 0 0 0.5em; }
  .change_pass_block .reg_col_1 {
    padding-right: 1em; }
  .change_pass_block .reg_col_2 {
    position: relative; }

.orders_btnm {
  text-align: right; }

.cart {
  padding: 0 0 0 2em;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .cart:before {
    content: '\f07a';
    font: normal 18px/1 "FontAwesome";
    position: absolute;
    color: #000;
    top: 2px;
    left: 0;
    z-index: 2; }
  .cart .header_cart-goods-quantity {
    color: #979797; }
  .cart .total {
    color: #979797;
    line-height: 1; }
  .cart .cart-title {
    color: #000;
    font-size: 14px;
    text-decoration: underline; }
  .cart .count {
    color: #222;
    font-size: 12px; }
  .cart.empty {
    cursor: default; }
  .cart.full {
    cursor: pointer; }

.cart-table__deliver {
  margin: 0 0 1em;
  table-layout: fixed;
  width: 100%; }
  .cart-table__deliver .dostavka_radio > input[type=radio] {
    cursor: pointer; }
  .cart-table__deliver tr > td {
    padding: 6px 0; }
  .cart-table__deliver tr:first-child {
    border-bottom: 1px solid #d8d8d8; }
  .cart-table__deliver tfoot {
    border-top: 1px solid #ccc; }

.dostavka_header {
  vertical-align: top; }

.desktop .cart.full:hover .cart-title {
  color: #347ea7;
  transition: color 0.2s ease; }

.desktop .cart.full:hover:before {
  color: #347ea7;
  transition: color 0.2s ease; }

.cart-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle; }
  .cart-table .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative; }
    .cart-table .cart-table__delete-btn:after {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(39deg); }
    .cart-table .cart-table__delete-btn:before {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(-39deg); }
  .cart-table .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%; }
  .cart-table .input-count {
    background: #fff;
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%; }
  .cart-table > thead > tr {
    border-bottom: 1px solid #d8d8d8; }
    .cart-table > thead > tr > th {
      padding: 6px 0; }
      .cart-table > thead > tr > th:nth-child(1) {
        width: 15%; }
      .cart-table > thead > tr > th:nth-child(2) {
        width: 40%; }
      .cart-table > thead > tr > th:nth-child(3) {
        width: 10%; }
      .cart-table > thead > tr > th:nth-child(4) {
        width: 10%; }
      .cart-table > thead > tr > th:nth-child(5) {
        width: 15%; }
      .cart-table > thead > tr > th:nth-child(6) {
        width: 10%; }
  .cart-table > tbody > tr {
    border-bottom: 1px solid #d8d8d8; }
    .cart-table > tbody > tr > td {
      padding: 6px 0; }

.cart-form {
  background: #eeeeee;
  padding: 0.5em 1em 1em; }

.order-form {
  max-width: 480px;
  width: 100%; }
  .order-form .form_block {
    margin: 0 0 0.5em;
    max-width: 300px;
    position: relative; }
  .order-form .picker-input {
    cursor: pointer; }
  .order-form .form_title {
    font-size: 14px;
    position: static; }
  .order-form .input-textarea {
    resize: none;
    height: 6em;
    width: 100%; }
  .order-form .input-text {
    width: 100%; }
  .order-form .required-message {
    font-size: 13px;
    margin: -10px 0 1em; }

.user-orders {
  margin: 0 0 1em; }

.order_ok_table {
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .order_ok_table th,
  .order_ok_table td {
    padding: 6px 0; }
  .order_ok_table tr {
    border-bottom: 1px solid #d8d8d8; }

.user-orders-deliver-table {
  border-bottom: 1px solid #d8d8d8;
  margin: 0 0 1em;
  text-align: left;
  table-layout: fixed;
  width: 100%; }
  .user-orders-deliver-table > tbody > tr {
    border-bottom: 1px solid #d8d8d8; }

.user-order > .total-table,
.user-order > .summ-table {
  table-layout: fixed;
  width: 275px; }
  .user-order > .total-table td:first-child,
  .user-order > .summ-table td:first-child {
    width: 200px; }
  .user-order > .total-table td:last-child,
  .user-order > .summ-table td:last-child {
    width: 75px; }
  .user-order > .total-table .cart_prices,
  .user-order > .summ-table .cart_prices {
    text-align: right; }

.user-order > .total-table {
  margin: 0 0 1em; }

.order-table {
  margin: 1em 0;
  text-align: center;
  table-layout: fixed;
  width: 100%; }
  .order-table td {
    padding: 6px 0; }
  .order-table > thead > tr {
    border-bottom: 1px solid #d8d8d8; }
  .order-table > tbody > tr {
    border-bottom: 1px solid #d8d8d8; }

.users-orders-table {
  margin: 1em 0;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle; }
  .users-orders-table .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative; }
    .users-orders-table .cart-table__delete-btn:active:before, .users-orders-table .cart-table__delete-btn:active:after {
      background: #392608; }
    .users-orders-table .cart-table__delete-btn:after {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(39deg); }
    .users-orders-table .cart-table__delete-btn:before {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(-39deg); }
  .users-orders-table .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%; }
  .users-orders-table .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%; }
  .users-orders-table > thead > tr {
    border-bottom: 1px solid #d8d8d8; }
    .users-orders-table > thead > tr > th {
      padding: 6px 0; }
      .users-orders-table > thead > tr > th:nth-child(1) {
        width: 15%; }
      .users-orders-table > thead > tr > th:nth-child(2) {
        width: 33%; }
      .users-orders-table > thead > tr > th:nth-child(3) {
        width: 13%; }
      .users-orders-table > thead > tr > th:nth-child(4) {
        width: 16%; }
      .users-orders-table > thead > tr > th:nth-child(5) {
        width: 10%; }
      .users-orders-table > thead > tr > th:nth-child(6) {
        width: 13%; }
  .users-orders-table > tbody > tr {
    border-bottom: 1px solid #d8d8d8; }
    .users-orders-table > tbody > tr > td {
      padding: 8px 0; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
footer .logo {
  float: left;
  padding: 0 4em 0 1em;
  margin: 0;
  width: 32%; }

footer .search {
  float: left;
  padding: 0 1em;
  width: 45%; }

footer .contacts-block {
  float: right;
  padding: 0 1em 0 3em;
  width: 23%; }

footer {
  background: #1b4557;
  padding: 80px 0 30px;
  position: relative; }
  footer .company-description {
    color: #fff;
    font-size: 16px;
    line-height: 1.2;
    padding: 0 0 1em; }
  footer .logo > a {
    display: block; }
  footer .logo img {
    display: block;
    height: auto;
    margin: 0 0 1em;
    max-width: 232px;
    position: relative;
    z-index: 2;
    width: 100%; }
  footer .ttl {
    color: #cdcdcd;
    font-size: 12px;
    display: block;
    line-height: 1.333; }
  footer .phones {
    display: block;
    margin: 0 0 1em;
    padding: 0 0 0 0.75em;
    position: relative; }
    footer .phones .phones-block {
      display: inline-block; }
      footer .phones .phones-block .icon {
        left: -1em;
        top: 2px; }
        footer .phones .phones-block .icon:before {
          content: '\f095';
          color: #fff; }
    footer .phones .phone-item > a {
      color: #fff;
      cursor: default;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none; }
      footer .phones .phone-item > a .pref {
        font-weight: normal; }
  footer .mail {
    margin: 0 0 1em;
    padding: 0 0 0 0.75em;
    position: relative; }
    footer .mail .icon {
      top: -1px;
      left: -1em; }
      footer .mail .icon:before {
        content: '\f0e0';
        font-size: 14px;
        color: #fff; }
    footer .mail > a {
      color: #fff;
      display: block;
      font-size: 14px;
      text-decoration: underline; }
      footer .mail > a:hover {
        color: #347ea7;
        text-decoration: underline; }
  footer .addr {
    padding: 0 0 0 0.75em;
    position: relative;
    margin: 0 0 1.75em; }
    footer .addr .icon {
      left: -0.75em;
      top: 5px; }
      footer .addr .icon:before {
        content: '\f041';
        font-size: 18px;
        color: #fff; }
    footer .addr > a {
      color: #fff;
      display: block;
      font-size: 14px; }
      footer .addr > a:hover {
        color: #347ea7; }
  footer .search {
    margin: 0.25em 0 0; }
    footer .search > .search-form {
      margin: 0 0 1.5em;
      width: 100%; }
    footer .search .company-description {
      padding: 0 0 0.75em; }
  footer .social-block {
    margin: 0 0 1em; }
    footer .social-block .social-block__item {
      display: inline-block;
      margin: 0 8px 0 0; }
      footer .social-block .social-block__item a {
        border-radius: 50%;
        display: block;
        text-align: center;
        height: 30px;
        position: relative;
        transition: opacity 0.2s ease;
        text-decoration: none;
        width: 30px; }
        footer .social-block .social-block__item a:before {
          content: '';
          display: inline-block;
          font: normal 18px/31px "FontAwesome";
          color: #fff;
          text-decoration: none; }
        footer .social-block .social-block__item a.si1 {
          background: #2f76a6; }
          footer .social-block .social-block__item a.si1:before {
            content: "\f189"; }
        footer .social-block .social-block__item a.si2 {
          background: #f4992c; }
          footer .social-block .social-block__item a.si2:before {
            content: "\f263"; }
        footer .social-block .social-block__item a.si3 {
          background: #3c5b9b; }
          footer .social-block .social-block__item a.si3:before {
            content: "\f09a"; }
        footer .social-block .social-block__item a.si4 {
          background: #2daae1; }
          footer .social-block .social-block__item a.si4:before {
            content: "\f099"; }
        footer .social-block .social-block__item a.si5 {
          background: #ca3b29; }
          footer .social-block .social-block__item a.si5:before {
            content: "\f0d5"; }
        footer .social-block .social-block__item a.si6 {
          background: #014587; }
          footer .social-block .social-block__item a.si6:before {
            content: "\f1fa"; }
        footer .social-block .social-block__item a.si7 {
          background: #fe3432; }
          footer .social-block .social-block__item a.si7:before {
            content: "\f16a"; }
        footer .social-block .social-block__item a.si8 {
          background: #c13584; }
          footer .social-block .social-block__item a.si8:before {
            content: "\f16d"; }
        footer .social-block .social-block__item a:hover {
          opacity: 0.6; }
  footer .counters-block {
    margin: 1em 0 0;
    text-align: right; }
    footer .counters-block > a {
      display: inline-block;
      margin: 0 5px; }
    footer .counters-block > .citron-logo__link {
      position: relative;
      vertical-align: text-bottom; }
      footer .counters-block > .citron-logo__link:hover .citron-logo--hover {
        opacity: 1;
        visibility: visible; }
    footer .counters-block .citron-logo--hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
      visibility: hidden; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.feedback-form {
  margin: 1em 0 0; }
  .feedback-form .feedback-col-6 {
    float: left;
    padding: 0 0.5em 0 0;
    width: 67%; }
    .feedback-form .feedback-col-6.last {
      padding: 0 0 0 0.5em;
      width: 33%; }
      .feedback-form .feedback-col-6.last .feedback-block {
        margin: 0 0 0.5em; }
  .feedback-form .feedback-button-block {
    float: left;
    margin: 4px 0 0;
    width: 100%; }
  .feedback-form input {
    width: 100%; }
  .feedback-form textarea {
    resize: none;
    height: 154px;
    width: 100%; }
  .feedback-form .button {
    width: auto; }
  .feedback-form .form_title {
    position: static; }
  .feedback-form .feedback-block.check-agree {
    margin: 0; }

.form_content {
  position: relative; }

.has-error > .input-text,
.has-error > .input-textarea {
  border-color: rgba(253, 30, 15, 0.85) !important; }

.has-success > .input-text,
.has-success > .input-textarea {
  border-color: rgba(169, 253, 0, 0.85) !important; }

.form_title {
  color: #543e1c;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  top: 14px;
  left: 0.5em;
  z-index: 10; }

.help-block {
  font-size: 12px;
  position: absolute;
  top: 8px;
  right: 1em; }
  .help-block.form-error {
    color: rgba(253, 30, 15, 0.85); }

.feedback-block.module-inner {
  margin: 2em 0 0;
  padding: 0 0 1em; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.vote-block .flag {
  position: relative; }
  .vote-block .flag:before {
    content: '\f00c';
    color: #515151;
    font: normal 13px/1 "FontAwesome";
    margin: 0 0 0 5px; }

.vote-block > .vote-block__box {
  padding: 1em 2em; }
  .vote-block > .vote-block__box.voted .vote-block__box-innr {
    width: 100%; }
  .vote-block > .vote-block__box .vote-block__box-innr {
    list-style: none;
    margin: 0 0 1em; }
    .vote-block > .vote-block__box .vote-block__box-innr > li > label {
      color: #515151;
      font-size: 15px;
      position: relative;
      padding: 0 0 0 1.5em; }
  .vote-block > .vote-block__box .vote-block__otvet-var {
    color: #515151;
    font-size: 13px;
    position: relative;
    line-height: 13px;
    padding: 5px 70px 5px 0; }
  .vote-block > .vote-block__box .vote-block__otvet_-var-percent {
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: 5px;
    bottom: -15px; }
  .vote-block > .vote-block__box .vote-block__line {
    height: 16px;
    background: #999;
    margin: 0 0 5px; }
  .vote-block > .vote-block__box .vote-block__line-in {
    height: 16px;
    background: #347ea7;
    width: 0;
    will-change: width;
    transition: all 0.5s ease-in; }
  .vote-block > .vote-block__box .vote-block__total {
    color: #515151;
    font-size: 12px;
    padding-top: 10px; }
  .vote-block > .vote-block__box .vote-block__total-percent {
    bottom: 5px; }

.vote-block .vote-block__title {
  color: #000;
  font-weight: bold;
  font-size: 15px;
  margin: 0 0 1em; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.search {
  float: right;
  padding: 0 1em;
  width: 21%; }

.search-form {
  position: relative; }
  .search-form .search-input {
    color: #515151;
    padding: 7px 71px 7px 12px;
    width: 100%; }
  .search-form .search-form__button {
    background: transparent;
    display: inline-block;
    color: #515151;
    line-height: 31px;
    margin: 1px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    text-transform: none;
    width: 5em;
    z-index: 90; }
    .search-form .search-form__button.disabled {
      background: none;
      color: #515151;
      cursor: default; }
    .search-form .search-form__button:not(.disabled):hover {
      background: transparent;
      color: #347ea7; }

.price-block {
  color: #347ea7;
  font-size: 16px; }
  .price-block > span {
    color: #222;
    font-size: 20px;
    font-weight: bold; }

.main_src_text {
  min-height: 95px; }

.search_box .buy_link {
  float: none;
  display: inline-block; }

.content_search img {
  margin: 0 10px 10px 0; }

.content_search h3 {
  margin: 0;
  font-size: 14px; }

.content_search div.search_box {
  margin: 0 0 15px; }

.content_search cite {
  margin: 2px 0;
  font-style: normal;
  color: #ca0d1f; }

.content_search p {
  margin: 0; }

.search_result {
  position: absolute;
  top: 30px;
  left: 0;
  margin: 4px 0 0;
  background: #fff;
  border-radius: 5px;
  width: 300px;
  font-size: 11px;
  line-height: 16px;
  z-index: 1500;
  text-align: left; }

.search_result > div {
  box-shadow: 1px 1px 5px #666;
  border: 1px solid #C6C6C6; }

.search_result > div > .search_box {
  padding: 5px 5px;
  border-top: 1px solid #C6C6C6;
  cursor: pointer; }

.search_result > div > .search_box p {
  margin: 0; }

.search_result > div > .search_box:hover {
  background: #eee; }

.search_result > div > .search_box:first-child {
  padding: 2px 5px;
  border-top: none; }

.search_result > div > .search_box img {
  margin: 0 10px 0; }

span.search_sell {
  color: #000;
  background: #ffff00; }

.ui-autocomplete-category {
  font-weight: bold;
  padding: 0.2em 0.4em;
  margin: 0.8em 0 0.2em;
  line-height: 1.5;
  font-size: 16px; }

.ui-autocomplete-category:first-child {
  padding: 0 0.4em !important;
  margin: 0 0 0.2em !important; }

.ui-state-focus {
  background: #eee !important;
  border: none !important; }

.ui-autocomplete-category {
  border-bottom: 1px solid #bababa; }

.ui-menu-item {
  margin: 0 !important;
  padding: .4em 1em !important;
  width: auto !important; }

.ui-autocomplete {
  z-index: 100000 !important; }

.top-menu_search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px; }

.img_search .search_form > .search_input {
  padding-right: 45px; }

.img_search .search_form > .search_button {
  width: 45px;
  height: 55px;
  background: url("../images/img_search.png") 50% -6px no-repeat;
  color: #fff; }

.ui-menu-item .ui-menu-item-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.news-block {
  padding: 0 0 1em; }
  .news-block .news {
    overflow: hidden;
    padding: 1em 1em 0 2em;
    position: relative;
    margin: 0 0 1em; }
  .news-block .news-block__title > a {
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.333;
    text-decoration: none; }
    .news-block .news-block__title > a:hover {
      text-decoration: none; }
  .news-block .news-block__text {
    font-size: 15px;
    margin: 0.5em 0 1.5em;
    line-height: 1.333; }
  .news-block .news-block__date {
    position: relative; }
    .news-block .news-block__date > span {
      background: #eeeeee;
      color: #a8a8a8;
      font-size: 14px;
      padding: 1px 2px 0;
      white-space: nowrap;
      position: relative;
      z-index: 1; }
    .news-block .news-block__date:before {
      background: #dadad9;
      content: '';
      height: 1px;
      position: absolute;
      top: 50%;
      left: -2em;
      right: -1em; }
  .news-block .news-all {
    margin: 7px 0 0;
    text-align: center; }
    .news-block .news-all > a {
      font-size: 14px; }

.news_datetitle {
  display: table;
  margin: 0 0 1em;
  width: 100%; }

.news_date,
.news_title,
.news_icon {
  display: table-cell; }

.news_date {
  display: table-cell;
  font-size: 12px;
  text-align: right;
  width: 116px;
  min-width: 114px; }
  .news_date > span {
    font-size: 12px;
    color: #a8a8a8;
    padding: 1px 3px 2px; }
    .news_date > span.dt {
      background: #347ea7;
      color: #fff;
      display: inline-block; }
    .news_date > span:first-child {
      font-size: 12px;
      padding: 1px 0;
      display: block; }

.news_title {
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle; }

.news_icon {
  padding: 0 1em 0 0;
  vertical-align: bottom;
  width: calc(60px + 1em); }

.news_navigation {
  display: table;
  width: 100%; }
  .news_navigation > .prev_news {
    text-align: left; }
  .news_navigation > .next_news {
    text-align: right; }
  .news_navigation > .all_news {
    text-align: center; }
  .news_navigation > .all_news,
  .news_navigation > .next_news,
  .news_navigation > .prev_news {
    display: table-cell;
    font-size: 13px;
    width: 33.333%; }
    .news_navigation > .all_news > a,
    .news_navigation > .next_news > a,
    .news_navigation > .prev_news > a {
      text-decoration: underline; }
      .news_navigation > .all_news > a:hover,
      .news_navigation > .next_news > a:hover,
      .news_navigation > .prev_news > a:hover {
        text-decoration: underline; }

.article .news_icon > img {
  display: block;
  height: auto;
  max-width: 60px;
  width: 100%; }

.article .news_text {
  padding: 0 0 2em; }

.article .news_image {
  float: left;
  max-width: 130px;
  margin: 0 1em 0.5em 0;
  width: 100%; }

@media only screen and (max-width: 480px) {
  .article .news_text {
    font-size: 13px; } }

.content-inner .article {
  border-bottom: 1px solid #bababa;
  padding: 1em 0; }
  .content-inner .article:last-of-type {
    border-bottom: none; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.reg_reg-section table {
  max-width: 443px;
  width: 100%; }

.reg_reg-section .input-text {
  width: 100%; }

.reg_reg-section .reg_col_1 {
  font-size: 14px;
  padding: 0 1em 0 0;
  width: 150px; }

.reg_reg-section .reg_col_2 {
  display: block;
  margin: 0.5em 0;
  position: relative; }

.reg_reg-section .button {
  margin: 1em 0 0; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
textarea {
  padding: 0.5em 1em; }

.check-agree {
  padding: 0 0 0.5em;
  margin: -13px 0 0; }
  .check-agree label {
    cursor: pointer;
    font-size: 11px;
    display: inline-block;
    line-height: 1.1; }
    .check-agree label .redz {
      font-size: 14px; }
    .check-agree label.has-error {
      color: rgba(253, 30, 15, 0.85); }
  .check-agree .input-form {
    margin: 0 0.5em 0 0;
    vertical-align: bottom;
    width: auto; }

.payFlex {
  padding: 0 0 0 1.5em;
  margin: 0 0 1em; }
  .payFlex .payType {
    cursor: pointer;
    height: 35px;
    position: relative;
    margin: 0 0 0.5em; }
    .payFlex .payType > input[type=radio] {
      position: absolute;
      width: 14px;
      top: 50%;
      margin-top: -7px;
      left: -1.5em;
      cursor: pointer;
      height: 14px;
      line-height: 35px; }
    .payFlex .payType .desc {
      border: 1px solid #bababa;
      background-position: right center;
      background-repeat: no-repeat;
      padding: 0 0 0 1em;
      height: 35px;
      line-height: 36px; }
    .payFlex .payType.active {
      cursor: default; }
      .payFlex .payType.active .desc {
        border-color: #347ea7;
        background-color: #347ea7;
        color: #fff; }
      .payFlex .payType.active.nal .desc {
        color: #fff; }
      .payFlex .payType.active.robokassa .desc {
        background-image: url("/assets/images/robokassa_white.png") !important; }
    .payFlex .payType.robokassa .desc {
      border: 1px solid #bababa;
      background-position: 14px 1px;
      text-indent: -9999px;
      background-size: 90%; }
    .payFlex .payType.nal .desc {
      background-size: auto 175%;
      color: #515151;
      text-transform: uppercase; }

.pay_wrap {
  line-height: 33px;
  margin: 10px 0; }

.inline-block {
  display: inline-block;
  vertical-align: top; }

.paymethod-label {
  margin: 0 10px; }

.payment-seporate {
  text-align: left;
  font-size: 20px;
  margin: 10px 0; }

.payment-big-icons-bankcards {
  background: url(/assets/images/payment-icons.png) -123px 0 no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-yandex-dengi {
  background: url(/assets/images/payment-icons.png) -32px -91px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-webmoney-rub {
  background: url(/assets/images/payment-icons.png) 0 -123px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-terminals {
  background: url(/assets/images/payment-icons.png) -155px 0 no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-alfa-click {
  background: url(/assets/images/payment-icons.png) 0 -91px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-sb-online {
  background: url(/assets/images/payment-icons.png) -64px -123px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-promsvyaz {
  background: url(/assets/images/payment-icons.png) -123px -32px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-masterpass {
  background: url(/assets/images/payment-icons.png) -64px -91px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-kupivkredit {
  background: url(/assets/images/payment-icons.png) -123px -64px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-qppiru {
  background: url(/assets/images/payment-icons.png) -32px -123px no-repeat;
  width: 32px;
  height: 32px; }

.payment-big-icons-kiwi {
  background: url(/assets/images/payment-icons.png) -155px -32px no-repeat;
  width: 32px;
  height: 32px; }

.ui-datepicker {
  border-radius: 0 !important; }

.ui-datepicker .ui-widget-header {
  border-radius: 0 !important;
  background: #347ea7;
  color: #fff; }

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important; }

.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a {
  color: #347ea7 !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 url(images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x !important;
  border: 1px solid #d3d3d3 !important; }

.ui-datepicker td.ui-datepicker-today > a {
  color: #fff !important;
  background: #347ea7 !important;
  border: 1px solid #347ea7 !important; }

.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid #347ea7 !important; }

.ui-datepicker th.ui-datepicker-week-end > span {
  color: #347ea7 !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a:hover {
  color: #347ea7 !important; }

/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.popup-block__fon {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 989;
  position: fixed; }

.popup-block {
  background: #fff;
  box-shadow: 1px 3px 14px 0 rgba(0, 0, 0, 0.2);
  display: none;
  font-size: 15px;
  width: 400px;
  position: fixed;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 990; }
  .popup-block.reg-window {
    width: 448px; }
    .popup-block.reg-window .addition-block {
      display: inline-block;
      margin: 0 0 0 1em; }
      .popup-block.reg-window .addition-block > a:first-child {
        margin: 0 1em 0 0; }
    .popup-block.reg-window .restore_pass-section {
      padding: 1em; }
      .popup-block.reg-window .restore_pass-section .popup-block__title {
        margin: 0; }
      .popup-block.reg-window .restore_pass-section .form_block {
        padding: 0.5em 0 1.75em; }
        .popup-block.reg-window .restore_pass-section .form_block.last {
          padding: 0.5em 0 0; }
          .popup-block.reg-window .restore_pass-section .form_block.last .form_content .help-block {
            right: 25%; }
    .popup-block.reg-window .form_title.captcha_title {
      font-size: 12px;
      top: -9px; }
    .popup-block.reg-window .form_block {
      margin: 0;
      padding: 0.5em 0 0.75em; }
      .popup-block.reg-window .form_block.form_block__last {
        display: inline-block; }
      .popup-block.reg-window .form_block.key-title {
        padding: 0;
        position: relative;
        top: -15px; }
      .popup-block.reg-window .form_block.last .key-img {
        display: inline-block;
        height: auto;
        position: absolute;
        right: 0;
        top: -10px;
        width: 20%; }
      .popup-block.reg-window .form_block.last .input-text {
        display: inline-block;
        width: 78%; }
  .popup-block.auth-ok .popup-block__inner {
    text-align: center; }
  .popup-block.auth-error .popup-block__inner {
    text-align: center; }
  .popup-block.add2cart {
    width: 416px; }
    .popup-block.add2cart .popup-block__inner {
      text-align: center; }
    .popup-block.add2cart .popup-block__title {
      margin: 0 0 0.5em; }
    .popup-block.add2cart .popup-block__content {
      text-align: center; }
      .popup-block.add2cart .popup-block__content > a {
        display: inline-block; }
        .popup-block.add2cart .popup-block__content > a:first-child {
          margin: 0 1em 0 0; }
  .popup-block.add-prod-comment .popup-block__inner {
    text-align: center; }
  .popup-block.sendformok {
    display: block;
    padding: 0;
    text-align: center; }
    .popup-block.sendformok .popup-block__inner {
      text-align: center; }
  .popup-block.reg-done {
    display: block;
    padding: 0;
    text-align: center; }
    .popup-block.reg-done .popup-block__inner {
      text-align: center; }
  .popup-block .popup-block__content {
    backface-visibility: hidden;
    text-align: center; }
  .popup-block .form_block {
    padding: 0.5em 0 1em;
    position: relative;
    text-align: left; }
    .popup-block .form_block.form_block__last {
      padding: 7px 0 0; }
      .popup-block .form_block.form_block__last > .form_title {
        font-size: 11px;
        top: -16px !important; }
  .popup-block .input-text {
    width: 100%; }
  .popup-block .input-textarea {
    height: 8em;
    resize: none;
    width: 100%; }
  .popup-block .popup-block__inner {
    padding: 1em;
    position: relative; }
  .popup-block .popup-block__cross {
    cursor: pointer;
    position: absolute;
    top: -0;
    right: 0;
    height: 32px;
    width: 32px;
    z-index: 3;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
    z-index: 1000; }
    .popup-block .popup-block__cross:before {
      content: '\f00d';
      color: #515151;
      position: absolute;
      font: normal 22px/1 "FontAwesome";
      top: 4px;
      left: 9px;
      z-index: 2; }
    .popup-block .popup-block__cross:hover:before {
      color: #347ea7; }
    .popup-block .popup-block__cross:active:before {
      top: 5px;
      left: 10px; }
  .popup-block .popup-block__title {
    color: #515151;
    font-size: 21px;
    font-weight: bold;
    margin: 0 0 0.25em;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    backface-visibility: hidden; }
  .popup-block.fast_order_form {
    padding: 1em;
    height: 14.5em;
    margin-top: -7.25em; }
    .popup-block.fast_order_form .form_block.form_block__last {
      text-align: center; }

.popup-block.compare-popup {
  padding: 1em;
  top: 50%;
  left: 50%;
  height: 800px;
  margin: -400px 0 0 calc(-600px + 1em);
  transform: none;
  width: calc(1200px - 2em); }
  @media only screen and (max-width: 1200px) {
    .popup-block.compare-popup {
      margin: -400px 0 0;
      left: 1em;
      right: 1em;
      width: auto; } }

.popup-block.compare-popup-error {
  width: 404px;
  margin: -39px 0 0 -202px;
  transform: none; }
