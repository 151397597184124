@import "vars";

textarea {
  padding: 0.5em 1em;
}


.check-agree {
  padding: 0 0 0.5em;
  margin: -13px 0 0;

  label {
    cursor: pointer;
    font-size: 11px;
    display: inline-block;
    line-height: 1.1;

    .redz {
      font-size: 14px;
    }

    &.has-error {
      color: $colorError;
    }
  }

  .input-form {
    margin: 0 0.5em 0 0;
    vertical-align: bottom;
    width: auto;
  }

  .help-block {

  }

}



.payFlex{
  padding: 0 0 0 1.5em;
  margin: 0 0 1em;

  .payType {
    cursor: pointer;
    height: 35px;
    position: relative;
    margin: 0 0 0.5em;


    & > input[type=radio] {
      position: absolute;
      width: 14px;
      top: 50%;
      margin-top: -7px;
      left: -1.5em;
      cursor: pointer;
      height: 14px;
      line-height: 35px;
    }

    .desc {
      border: 1px solid $inputBorderColor;
      background-position: right center;
      background-repeat: no-repeat;
      padding: 0 0 0 1em;
      height: 35px;
      line-height: 36px;
    }

    &.active {

      cursor: default;

      .desc {
        border-color: $linkColor;
        background-color: $linkColor;
        color: #fff;
      }

      &.nal {

        .desc {
          color: $white;
        }
      }

      &.robokassa {

        .desc {
          background-image: url('/assets/images/robokassa_white.png') !important;
        }
      }
    }


    &.robokassa {
      .desc {
        border: 1px solid #bababa;
        background-position: 14px 1px;
        text-indent: -9999px;
        background-size: 90%;
      }
    }


    &.nal {
      .desc {
        background-size: auto 175%;
        color: $textColor;
        text-transform: uppercase;
      }
    }
  }

}


.kassa-block {

}



.pay_wrap {
  line-height: 33px;
  margin: 10px 0;
}

.inline-block {
  display: inline-block;
  vertical-align: top;
}

.paymethod-label {
  margin: 0 10px;
}

.payment-seporate {
  text-align: left;
  font-size: 20px;
  margin: 10px 0;
}

.payment-big-icons-bankcards {
  background: url(/assets/images/payment-icons.png) -123px 0 no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-yandex-dengi {
  background: url(/assets/images/payment-icons.png) -32px -91px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-webmoney-rub {
  background: url(/assets/images/payment-icons.png) 0 -123px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-terminals {
  background: url(/assets/images/payment-icons.png) -155px 0 no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-alfa-click {
  background: url(/assets/images/payment-icons.png) 0 -91px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-sb-online {
  background: url(/assets/images/payment-icons.png) -64px -123px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-promsvyaz {
  background: url(/assets/images/payment-icons.png) -123px -32px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-masterpass {
  background: url(/assets/images/payment-icons.png) -64px -91px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-kupivkredit {
  background: url(/assets/images/payment-icons.png) -123px -64px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-qppiru {
  background: url(/assets/images/payment-icons.png) -32px -123px no-repeat;
  width: 32px;
  height: 32px;
}

.payment-big-icons-kiwi {
  background: url(/assets/images/payment-icons.png) -155px -32px no-repeat;
  width: 32px;
  height: 32px;
}




.ui-datepicker {
  border-radius: $borderRadius !important;
}

.ui-datepicker .ui-widget-header {
  border-radius: $borderRadius !important;
  background: $linkColor;
  color: #fff;
}


.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important;
}


.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important;
}


.ui-datepicker td.ui-datepicker-week-end > a {
  color: $linkColor !important;
  background: none !important;
  border: 1px solid transparent !important;
}

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 url(images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x !important;
  border: 1px solid #d3d3d3 !important;
}


.ui-datepicker td.ui-datepicker-today > a {
  color: #fff !important;
  background: $linkColor !important;
  border: 1px solid $linkColor !important;
}


.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid $linkColor !important;
}


.ui-datepicker th.ui-datepicker-week-end > span {
  color: $linkColor !important;
  background: none !important;
  border: 1px solid transparent !important;
}


.ui-datepicker td.ui-datepicker-week-end > a:hover {
   color: $linkColor !important;
}
