@import "../vars";



.compareCategories {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 0 1em;


  .trashAll {
    margin: 0 5px;
    padding: 4px 10px;
    cursor: pointer;
    background: #fff;
    outline: 1px solid #aaa;
  }
}



.comparison,
.comparisonz {
  display: flex;
}


.comparison .owl-stage-outer .owl-stage .owl-item {
  display: inline-block;
  vertical-align: top;
}

.comparison.owl-carousel .owl-nav .owl-prev,
.comparison.owl-carousel .owl-nav .owl-next {
  background: #e2e2e2;
  border-radius: 50%;
  color: #fff;
  line-height: 0.96em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center;
}



.comparison.owl-carousel .owl-nav .owl-prev:hover,
.comparison.owl-carousel .owl-nav .owl-next:hover {
  color: #aaa;
}

.comparison.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -0.5em;
}

.comparison.owl-carousel .owl-nav .owl-prev {
  left: -0.5em;
  right: auto;
}

.comparison.owl-carousel .owl-nav {
  top: 40%;
}



.comparison {

  .container {
    padding: 5px 0 0;
    margin: 0 2px;
    width: 220px;
    box-shadow: 0 0 4px -1px #464646;
    background: #fff;
    text-align: center;


  }

}


.comparison .container > div.general .removeItem{
  padding: 10px 0;
  cursor: pointer;
}

.comparison .container > div.general .numbered {
  width: 30px;
  position: absolute;
  background: $linkColor;
  color: #fff;
  border-radius: 0 0 20px;
  top: 0;
  left: 0;
  padding: 5px;
}

.comparison .container > div {
  padding: 10px 0 0;
  margin: 0 0 2px;
}

.comparison .container > div .linkTitle{
  text-transform: uppercase;
  padding: 0 0 5px;
}

.comparison .container > div .head {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0 4px -1px #464646;
  background: #f7f7f7;
  font-size: 12px;
  cursor: pointer;
}

.comparison .container > div .priceOld {
  text-decoration: line-through;
  color: #aaa;
}

.comparison .container > div .generalParam:nth-child(odd) {
  background: #ccc;
}

.comparison .container > div .generalParam .inners{
  padding: 5px 0;
}

.comparison .container > div .generalParam .inners b {
  font-size: 20px;
  line-height: 14px;
}

.comparison .container > div .generalParam .param_tsvet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center
}

.comparison .container > div .generalParam .param_tsvet .colorPunkt {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 15px;
}

.compare_box{
  padding: 0 5px;
}


.compare_text{
  align-self: center;
  cursor: pointer;
  font-size: 13px;
}


.owl-placeholder{
  border: 1px solid green;
  background-color: white;
  box-shadow: 0 0 10px $borderColor;
}
