@import "vars";
@import "icon";



// Тут только раскладка!
.header-top {
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;

	.logo {
		float: left;
		padding: 0 1em;
		width: 24%;

		& > a {
			display: block;
		}
	}

	.timeswork-block {
		float: left;
		padding: 0 1em;
		width: 20%;
	}

	.contacts {
		float: left;
		padding: 0 1em;
		width: 40%;
	}

	.header-cart {
		float: right;
		padding: 0 1em;
		position: relative;
		width: 16%;
	}
}

// конец раскладки



// Стили оформления
.header-top {
	padding: 2.5em 0 2em;

	.logo {

		img {
			display: block;
			height: auto;
			max-width: 246px;
			width: 100%;
		}
	}

	.phones {
		display: inline-block;
		vertical-align: top;
	}

	.phones-list {
		display: inline-block;
		list-style: none;
		position: relative;

		.icon {
			margin-top: -7px;

			&:before {
				content: '\f095';
			}
		}
	}

	.phones-list__item {
		display: inline-block;
		margin: 0 0.5em 0 0;

		&:last-child {
			margin: 0;
		}

		& > a {
			color: $black;
			cursor: default;
			font-size: 14px;
			font-weight: bold;
			text-decoration: none;
			white-space: nowrap;

			.pref {
				font-weight: normal;
			}
			
			&:hover {
				color: $black;
			}
		}


		& > span {
			color: $textColor + 70%;
			display: block;
			font-size: 12px;
			line-height: 1;
			text-align: left;
		}
	}


	.mail {
		display: inline-block;
		padding: 0 0 0 4em;
		vertical-align: top;
	}

	.contacts {
		position: relative;
		text-align: center;
	}

	.mail-block {
		position: relative;

		.icon {
			height: 19px;
			width: 23px;
			top: 0.25em;

			&:before {
				content: '\f0e0';
			}
		}

		& > span {
			color: $textColor + 70%;
			display: block;
			font-size: 12px;
			line-height: 1;
			margin: 0 0 0 5px;
			text-align: left;
		}
		

		& > a {
			color: $black;
			font-size: 14px;
			line-height: 1;
			margin: 0 0 0 5px;
			text-align: left;
			
			&:hover {
				color: $linkColor;
			}
		}
	}


	.timeswork-block {
		padding: 0 1em 0 1.5em;
		position: relative;

		&:before {
			content: '\f017';
			color: #000;
			font: normal 14px/1 "FontAwesome";
			position: absolute;
			top: 3px;
			left: 0;
			
		}
	}


	.time-wrap {
		float: left;
		width: 50%;
		font-weight: bold;
		font-size: 14px;

		& > span {
			color: $textColor + 70%;
			display: block;
			font-size: 12px;
			font-weight: normal;
			line-height: 1.5;
			
			& > i {
				font-size: 10px;
				font-style: normal;
			}

			& > sup {
				font-size: 8px;
			}

		}
	}


	.search {
		position: relative;
	}
}



