@import "../vars";

.module {
  &.ajaxflowcart {
    background: #eeeeee;
    overflow: visible;
    margin: 0;
  }
}


.ajaxflowcart {
  box-shadow: 1px 2px 7px -2px #ccc;
  right: 1em;
  top: 113%;
  padding: 5px;
  margin: 0;
  position: absolute;
  text-align: center;
  width: 350px;
  z-index: 24000;


  .price {
    color: $linkColor;
    font-size: 20px;
  }

  .currency {
    color: $textColor;
    font-size: 18px;
  }

  & > table {
    width: 100%;

    td {
      padding: 5px;
      border-top: 1px solid #d8d8d8;
    }
  }

  tr {
    &:first-child {
      & > td {
        border-top: none;
      }
    }
  }

  .ajaxflowcart_kill {
    color: $linkColor;
    font-style: normal;
    padding: 0;

    & > span {
      cursor: pointer;
      padding: 0.25em 0.5em;
    }
  }


  .ajaxflowcart_photo {

  }

  .ajaxflowcart_title {
    text-align: left;

    & > .prod-param {
      color: $textColor;
      font-size: 12px;
      text-align: left;
    }


    & > a {
      font-size: 14px;
      line-height: 1.2;
      display: block;
    }

  }

  .ajaxflowcart_count {
    color: $textColor;
    font-size: 12px;
    white-space: nowrap;
  }

  .ajaxflowcart_price {
    white-space: nowrap;
  }

  .ajaxflowcart_summ {
    color: $textColor;
    font-size: 14px;
  }

  .button {
    margin: 5px 0 0;
  }

}


.profile-block {
  float: left;

  & > form {
    & > table {
      table-layout: fixed;

    }
  }

  .reg_col_1,
  .reg_col_2 {
    padding: 0 0 0.5em;
  }

  .reg_col_1 {
    padding-right: 1em;
  }

  .reg_col_2 {
    position: relative;
  }

}

.change_pass_block {
  float: right;

  .change_pass_block--title {
    font-size: 16px;
    padding: 0 0 1em;
    text-align: center;
  }

  .reg_col_1,
  .reg_col_2 {
    padding: 0 0 0.5em;
  }

  .reg_col_1 {
    padding-right: 1em;
  }

  .reg_col_2 {
    position: relative;
  }

}




.orders_btnm {
  text-align: right;
}



.cart {
  padding: 0 0 0 2em;
  position: relative;
  -webkit-tap-highlight-color: rgba(0,0,0,0.15);

  &:before {
    content: '\f07a';
    font: normal 18px/1 "FontAwesome";
    position: absolute;
    color: #000;
    top: 2px;
    left: 0;
    z-index: 2;
  }

  .header_cart-goods-quantity {
    color: $textColor + 70%;
  }

  .total {
    color: $textColor + 70%;
    line-height: 1;
  }

  .cart-title {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
  }

  .count {
    color: $black;
    font-size: 12px;
  }

  &.empty {
    cursor: default;
  }

  &.full {
    cursor: pointer;
  }
}




.cart-table__deliver {
  margin: 0 0 1em;
  table-layout: fixed;
  width: 100%;


  .dostavka_radio {
    & > input[type=radio] {
      cursor: pointer;
    }
  }

  tr {

    & > td {
      padding: 6px 0;
    }

    &:first-child {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  tfoot {
    border-top: 1px solid #ccc;
  }
}



.dostavka_header {
  vertical-align: top;
}


.desktop {
  .cart {
    &.full {
      &:hover {

        .cart-title {
          color: $linkColor;
          transition: color 0.2s ease;
        }

        &:before {
          color: $linkColor;
          transition: color 0.2s ease;
        }
      }
    }
  }
}


.cart-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;

  .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative;

    &:active {

      &:before,
      &:after {
        //background: #392608;
      }
    }

    &:after {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(39deg);
    }

    &:before {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(-39deg);
    }
  }


  .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%;
  }

  .input-count {
    background: #fff;
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%;
  }

  & > thead {
    & > tr {
      border-bottom: 1px solid #d8d8d8;

      & > th {
        padding: 6px 0;

        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 40%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 15%;
        }
        &:nth-child(6) {
          width: 10%;
        }

      }
    }
  }

  & > tbody {
    & > tr {
      border-bottom: 1px solid #d8d8d8;

      & > td {
        padding: 6px 0;
      }
    }
  }
}


.cart-form {
  background: #eeeeee;
  padding: 0.5em 1em 1em;
}



.order-form {
  max-width: 480px;
  width: 100%;
  
  .form_block {
    margin: 0 0 0.5em;
    max-width: 300px;
    position: relative;
  }

  .picker-input {
    cursor: pointer;
  }

  .form_title {
    font-size: 14px;
    position: static;
  }

  .input-textarea {
    resize: none;
    height: 6em;
    width: 100%;
  }

  .input-text {
    width: 100%;
  }

  .required-message {
    font-size: 13px;
    margin: -10px 0 1em;
  }
}


.user-orders {
  margin: 0 0 1em;
}


.order_ok_table {
  table-layout: fixed;
  text-align: left;
  width: 100%;

  th,
  td {
    padding: 6px 0;
  }

  tr {
    border-bottom: 1px solid #d8d8d8;
  }

}




.user-orders-deliver-table {
  border-bottom: 1px solid #d8d8d8;
  margin: 0 0 1em;
  text-align: left;
  table-layout: fixed;
  width: 100%;


  & > tbody {
    & > tr {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}


.user-order {

  & > .total-table,
  & > .summ-table {
    table-layout: fixed;
    width: 275px;

    td {
      &:first-child {
        width: 200px;
      }

      &:last-child {
        width: 75px;
      }
    }

    .cart_prices {
      text-align: right;
    }
  }

  & > .total-table {
    margin: 0 0 1em;
  }
}


.order-table {
  margin: 1em 0;
  text-align: center;
  table-layout: fixed;
  width: 100%;

  td {
    padding: 6px 0;
  }

  & > thead {
    & > tr {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  & > tbody {
    & > tr {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}



.users-orders-table {
  margin: 1em 0;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;

  .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative;

    &:active {

      &:before,
      &:after {
        background: #392608;
      }
    }

    &:after {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(39deg);
    }

    &:before {
      background: #fff;
      content: '';
      width: 2px;
      position: absolute;
      display: block;
      top: 8px;
      bottom: 8px;
      left: 13px;
      transform: rotate(-39deg);
    }
  }


  .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%;
  }

  .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%;
  }

  & > thead {
    & > tr {
      border-bottom: 1px solid #d8d8d8;

      & > th {
        padding: 6px 0;

        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 33%;
        }
        &:nth-child(3) {
          width: 13%;
        }
        &:nth-child(4) {
          width: 16%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 13%;
        }

      }
    }
  }

  & > tbody {
    & > tr {
      border-bottom: 1px solid #d8d8d8;

      & > td {
        padding: 8px 0;
      }
    }
  }

}


