@font-face {
	font-family: 'FontAwesome';
	src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.6.3');
	src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.6.3') format('woff2'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.6.3') format('woff'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.6.3') format('truetype'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/latoregular/Lato-Regular.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/latoregular/Lato-Regular.woff') format('woff'),
	url('/assets/fonts/latoregular/Lato-Regular.ttf') format('truetype'),
	url('/assets/fonts/latoregular/Lato-Regular.svg#Lato-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/latobold/Lato-Bold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/latobold/Lato-Bold.woff') format('woff'),
	url('/assets/fonts/latobold/Lato-Bold.ttf')  format('truetype'),
	url('/assets/fonts/latobold/Lato-Bold.svg#Lato-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/latolight/Lato-Light.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/latolight/Lato-Light.woff') format('woff'),
	url('/assets/fonts/latolight/Lato-Light.ttf')  format('truetype'),
	url('/assets/fonts/latolight/Lato-Light.svg#Lato-Light') format('svg');
	font-weight: 100;
	font-style: normal;
}


@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/latoitalic/Lato-Italic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/latoitalic/Lato-Italic.woff') format('woff'),
	url('/assets/fonts/latoitalic/Lato-Italic.ttf')  format('truetype'),
	url('/assets/fonts/latoitalic/Lato-Italic.svg#Lato-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
}
